import React from "react";
import "./style.css";
import { withRouter } from "react-router";
import LeftDrawer from "../../components/leftDrawer";
import ApiService from "../../services/ApiService";
import loader from "../../assets/images/loader.gif";
import Masonry from "react-responsive-masonry"
import {FiShare2, FiTrash} from 'react-icons/fi'
import { Button } from "@material-ui/core";
import SocialShare from '../../components/SocialShare';
import ModalBox from '../../components/Modal'
import ImageGallery from '../../components/MasonryListing/v1'

const ImageBox =({data,onClick, onDelete})=>{
  let image = data.image;
  return(
      <div className="img-box">
          <img src={image}/>           
          <Button style={{position:'absolute',bottom:10,right:0}} onClick={()=>{onDelete(data.id)}}>
              <FiTrash color={'#fff'} />    
          </Button> 
          
      </div>
  )
}


const ImageListing = ({onClick, images, onDelete})=>{
  return(
      <div className="list-group list-group-vertical">
          <Masonry columnsCount={2} gutter={'10px'}>
              {
                  images.map(image=>(
                  <ImageBox onClick={onClick} onDelete={onDelete} data={image}/>
                  ))
              }
                        
          </Masonry>
      </div>
  )
}

class MyDownloads extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      photos: [],
      page: 1,
      pageCount: 5,
      isLoading: true,
      nxtLoader: false,
      totalPhotos: 0,
      frSavedImages:[],
      deleteModal:false,
      imageId:null,
    };

    // this.getMyPhotos(1);
    
  }

  downloadImage(path) {
    fetch(path, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchFrSavedImages(page= 1){
    let params = {
      page: page,
      pageCount: this.state.pageCount,
    };
    ApiService.getData("savedImages", params, true).then((response) => {
      if (response.status == "success") {

          this.setState({frSavedImages:response.data});
      }
      this.setState({isLoading:false});
    }).catch(err=>{
      this.setState({isLoading:false});
    })
  }

  deleteImage(){

    ApiService.postData("deleteImage", {id:this.state.imageId}, true).then((response) => {
      if (response.status == "success") {
        this.fetchFrSavedImages();
        this.setState({deleteModal:false});
      }else{
        alert('Something went wrong. Please try again later.')
      }
    })
  }

  componentDidMount(){
    this.fetchFrSavedImages();
  }
  getMyPhotos(page) {
    if (page == 1) this.setState({ isLoading: true });
    else this.setState({ nxtLoader: true });

    let params = {
      page: page,
      pageCount: this.state.pageCount,
    };
    ApiService.getData("getMyPhotos", params, true).then((response) => {
      if (response.status == "success") {
        this.setState({
          photos: [...this.state.photos, ...response.data.data],
          page: page,
        });
        this.setState({ totalPhotos: response.data.total }, () => {
          setTimeout(() => {
            this.setState({ isLoading: false, nxtLoader: false });
          }, 4000);
        });
      } else {
      }
    });
  }

  render() {

    if(this.state.frSavedImages.length > 0){
      return(
        <div className="pageOuter">
        <div className="privacyGreenBlock">
          <div className="headerOuter">
            <LeftDrawer></LeftDrawer>
            <h4 className="headerTitle"></h4>
          </div>
          <div className="ppOuter">
            <h4 className="ppTitle">My Downloads</h4>
            <h3 className="ppTitle"></h3>
          </div>
        </div>
        <ModalBox 
        confirm={()=>this.deleteImage()}
        cancel={()=>{this.setState({deleteModal:false})}}
        isOpen={this.state.deleteModal}
         title={'Confirm'} 
         des={'Are you sure want to delete image?'}/>
          <div className="pp-content-outer">
            {/* <ImageGallery      
              onDelete={(id)=>this.setState({deleteModal:true, imageId:id})}
              images={this.state.frSavedImages}
              onClick={(item)=>{
                  this.setState({socialShare:true,image:item})}}
            /> */}
          <ImageListing
                onDelete={(id)=>this.setState({deleteModal:true, imageId:id})}
                images={this.state.frSavedImages}
                onClick={(item)=>{
                    this.setState({socialShare:true,image:item})}}
                />
               {/* <SocialShare
               image={this.state.image}
               close={()=>{this.setState({socialShare:false})}}
               isOpen={this.state.socialShare}
               quote={'Campaign Conference'} hashtag={['#conference',"#photo-contest"]} url={this.state.image}/> */}
          </div>
        </div>
      )
    }

    return (
      <div className="pageOuter">
        <div className="privacyGreenBlock">
          <div className="headerOuter">
            <LeftDrawer></LeftDrawer>
            <h4 className="headerTitle"></h4>
          </div>
          <div className="ppOuter">
            <h4 className="ppTitle">My Downloads</h4>
            <h3 className="ppTitle"></h3>
          </div>
        </div>
        <div className="pp-content-outer">
          {!this.state.isLoading ? (
            this.state.photos.length > 0 ? (
              this.state.photos.map((photo) => (
                <div className="item">
                  <img
                    src={photo.high_res_image}
                    className="downloadableImagePreview"
                  />
                  <a
                    className="downloadFb"
                    onClick={() => {
                      this.downloadImage(photo.high_res_image);
                    }}
                  >
                    <img
                      src={require("./../../assets/images/export.svg")}
                      alt="Export Post"
                      className="exportIcon"
                    />
                  </a>
                </div>
              ))
            ) : (
              <p className="noData">Nothing to show here!</p>
            )
          ) : (
            <div className="veloLoaderOuter">
              <img
                src={require("./../../assets/images/velo-logo.png")}
                alt="Logo loader"
                className="photosLoaderImg"
              />

              <p className="veloLoaderText">
                Please wait while we load your photos
              </p>
            </div>
          )}

          {!this.state.isLoading ? (
            <div className="loadBtnOuter">
              {this.state.nxtLoader ? (
                <Button className="loadBtn">Please wait..</Button>
              ) : this.state.totalPhotos == this.state.photos.length ? (
                <p className="pleaseWaitTxt">You have reached the end.</p>
              ) : (
                <Button
                  className="loadBtn"
                  onClick={() => {
                    this.getMyPhotos(this.state.page + 1);
                    this.setState({ nxtLoader: true });
                  }}
                >
                  Load More
                </Button>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
export default withRouter(MyDownloads);
