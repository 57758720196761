import React from "react";
import Modal from 'react-modal';
import { Button } from "@material-ui/core";

import {
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    WhatsappIcon,
    LinkedinIcon,
    PinterestIcon,
    TelegramIcon,
    TwitterIcon
  } from "react-share";

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: '80%',
      transform: 'translate(-50%, -50%)',
      // height:'250px',
    },
  };


  const SocialShare =({quote, hashtag,url,isOpen,close,image})=>{
    return(
        <Modal
        isOpen={isOpen}       
        contentLabel="Example Modal"
        style={customStyles}
      >
       <h4>Share Image</h4>
       {
        image &&
     
       <div style={{display:'flex',justifyContent:'center',paddingBottom:10,height:250, objectFit:'contain'}}>
        <img src={image} style={{minWidth:250}}/>
        </div>
          }
       <div>
        <FacebookShareButton url={url} quote={quote} hashtag={hashtag} ><FacebookIcon /></FacebookShareButton>
        <WhatsappShareButton url={url} title={quote}> <WhatsappIcon/> </WhatsappShareButton>
        <TwitterShareButton url={url} ><TwitterIcon/></TwitterShareButton>
        <LinkedinShareButton url={url} ><LinkedinIcon/> </LinkedinShareButton>      
        <TelegramShareButton url={url} ><TelegramIcon/></TelegramShareButton>
       </div>
       <Button  onClick={close}>
        <p >Close</p>
        </Button>
    </Modal>
    )
  }

  export default SocialShare;