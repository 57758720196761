import React, { useRef } from "react";
import "./style.css";
import { BsArrowLeft } from "react-icons/bs";
import { withRouter } from "react-router";
import ImageCropper from "../../components/cropper/imagecropper";
import HomeIcon from "@material-ui/icons/Home";
import { Link } from "react-router-dom";
import StorageService from "../../services/StorageService";
import AuthenticationService from "../../services/AuthenticationService";
import VideoValidationService from "../../services/VideoValidationService";
import { Constants } from "../../config";
import loaderNormal from "../../assets/images/loader-normal.gif";
import ApiService from "../../services/ApiService";
import Button from "@material-ui/core/Button";
import ProgressBar from "@ramonak/react-progress-bar";
import platform from "platform";

class CreateVideoPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPublished: false,
      showPublishBtn: false,
      sourceFile: "",
      uploadPercentage: 0,
      isLoading: false,
      username: this.props.location.data ? this.props.location.data.name : null,
      district: this.props.location.data
        ? this.props.location.data.district
        : null,
      participantId: this.props.location.data
        ? this.props.location.data.id
        : null,
      participantPublicId: this.props.location.data
        ? this.props.location.data.public_id
        : null,
      uploadTimer: 0,
      isSingleUpload: false,
      campaign_details: JSON.parse(StorageService.getData("campaign_details")),
    };

    let interval = 0;

    AuthenticationService.isCampaignActive(
      this.props.match.params["campaign_name"],
      this.props.match.params["event_name"]
    );

    this.calculateLoaderPercentage = this.calculateLoaderPercentage.bind(this);
  }

  componentDidMount() {
    if (!this.props.location.data) {
      this.goHome();
    }
  }

  componentWillUnmount() {}

  goHome() {
    // this.props.history.push("/profile");
    this.props.history.push(
      "/" +
        this.props.match.params["event_name"] +
        "/" +
        this.props.match.params["campaign_name"] +
        "/home"
    );
  }

  onPublished() {
    this.setState({
      isPublished: true,
    });
  }

  async onChange(e) {
    this.setState({ loaderMessage: "Loading your image.." });
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    const videoEl = document.createElement("video");
    videoEl.src = window.URL.createObjectURL(file);

    videoEl.onloadedmetadata = (event) => {
      window.URL.revokeObjectURL(videoEl.src);
      const { name, type, size } = file;
      const { videoWidth, videoHeight, duration } = videoEl;

      // if(
      //   !VideoValidationService.validateVideoResolution(videoHeight) ||
      //   !VideoValidationService.validateVideoFormat(type) ||
      //   !VideoValidationService.validateVideoDuration(duration) ||
      //   !VideoValidationService.validateVideoFilesize(size)
      // ){
      //   alert('Please upload a valid video')
      // }else{
      // this.setState({
      //   isLoading: true,
      // });
      this.setState({
        sourceFile: file,
      });
      let blobURL = URL.createObjectURL(file);
      document.querySelector("video").src = blobURL;

      // //this.uploadFileToDropBox(file);

      // }
    };
  }

  loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async uploadFileToDropBox(file) {
    this.setState({
      uploadTimer: 0,
    });

    const res = await this.loadScript(
      "https://unpkg.com/dropbox@5.2.1/dist/Dropbox-sdk.min.js"
    );

    if (!res) {
      alert("DropBox SDK failed to load. Are you online?");
      return;
    }

    setInterval(() => {
      this.setState({
        uploadTimer: this.state.uploadTimer + 1,
      });
    }, 1000);

    let custom_file_name =
      this.state.username.split(" ").join("-") +
      "_" +
      this.state.district.split(" ").join("-") +
      "_" +
      this.state.participantPublicId +
      "." +
      file.name.split(".").pop();
    const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;
    var ACCESS_TOKEN = Constants.DROPBOX_ACCESS_TOKEN;
    var dbx = new window.Dropbox.Dropbox({ accessToken: ACCESS_TOKEN });

    if (file.size < UPLOAD_FILE_SIZE_LIMIT) {
      // File is smaller than 150 Mb - use filesUpload API
      this.calculateLoaderPercentage2();
      dbx
        .filesUpload({
          path: "/" + Constants.DROPBOX_FOLDER_NAME + "/" + custom_file_name,
          contents: file,
        })
        .then((response) => {
          this.addParticipantVideoToDB(
            response.name,
            response.size,
            response.path_display
          );
        })
        .catch((error) => {
          console.error(error);
          this.setState({
            isLoading: false,
          });
          this.createUserFileUploadLog("failure", file.size);
          alert("Oops! Something went wrong. Please try again.");
        });
    } else {
      // File is bigger than 150 Mb - use filesUploadSession* API
      const maxBlob = 8 * 1000 * 1000; // 8Mb - Dropbox JavaScript API suggested max file / chunk size

      var workItems = [];

      var offset = 0;

      while (offset < file.size) {
        var chunkSize = Math.min(maxBlob, file.size - offset);
        workItems.push(file.slice(offset, offset + chunkSize));
        offset += chunkSize;
      }

      var calculate = this.calculateLoaderPercentage;

      const task = workItems.reduce((acc, blob, idx, items) => {
        if (idx == 0) {
          // Starting multipart upload of file
          return acc.then(function () {
            return dbx
              .filesUploadSessionStart({ close: false, contents: blob })
              .then((response) => {
                return response.session_id;
              });
          });
        } else if (idx < items.length - 1) {
          // Append part to the upload session
          return acc.then(function (sessionId) {
            var cursor = { session_id: sessionId, offset: idx * maxBlob };
            return dbx
              .filesUploadSessionAppendV2({
                cursor: cursor,
                close: false,
                contents: blob,
              })
              .then((response) => {
                calculate(idx, items.length);
                return sessionId;
              });
          });
        } else {
          // Last chunk of data, close session
          return acc.then(function (sessionId) {
            var cursor = {
              session_id: sessionId,
              offset: file.size - blob.size,
            };
            var commit = {
              path:
                "/" + Constants.DROPBOX_FOLDER_NAME + "/" + custom_file_name,
              mode: "add",
              autorename: true,
              mute: false,
            };
            return dbx
              .filesUploadSessionFinish({
                cursor: cursor,
                commit: commit,
                contents: blob,
              })
              .then((response) => {
                calculate(idx, items.length);
                return response;
              });
          });
        }
      }, Promise.resolve());

      task
        .then((result) => {
          this.addParticipantVideoToDB(
            result.name,
            result.size,
            result.path_display
          );
        })
        .catch((error) => {
          console.error(error);
          this.setState({
            isLoading: false,
          });
          this.createUserFileUploadLog("failure", file.size);
          alert("Oops! Something went wrong. Please try again.");
        });
    }
    return false;
  }

  addParticipantVideoToDB(file_name, size, path) {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.setState({ uploadPercentage: Math.round(100) });

    let postParams = {
      participant_id: this.state.participantId,
      storage_server: "Dropbox",
      storage_account: Constants.DROPBOX_ACCOUNT_HOLDER,
      file_name: file_name,
      file_path: path,
      size: size,
    };
    ApiService.postData("addParticipantVideo", postParams, true).then(
      (response) => {
        if (response.status == "success") {
          this.createUserFileUploadLog("success", size);
        } else {
          this.setState({ is_loading: false });
          this.props.history.push(
            "/" +
              this.props.match.params["event_name"] +
              "/" +
              this.props.match.params["campaign_name"] +
              "/error"
          );
        }
      }
    );
  }

  initiateUpload() {
    this.setState({
      isLoading: true,
    });
    this.uploadFileToDropBox(this.state.sourceFile);
  }

  calculateLoaderPercentage(currentVal, totalVal) {
    let percent = (100 * currentVal) / totalVal;
    this.setState({ uploadPercentage: Math.round(percent) });
  }

  calculateLoaderPercentage2() {
    let counter = 80;
    let percent = 0;
    this.interval = setInterval(() => {
      if (percent < 95) {
        percent = (100 * counter) / 250;
        this.setState({ uploadPercentage: Math.round(percent) });
        counter++;
      } else {
        clearInterval(this.interval);
      }
    }, 500);
  }

  finishUpload() {
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
      this.props.history.push(
        "/" +
          this.props.match.params["event_name"] +
          "/" +
          this.props.match.params["campaign_name"] +
          "/video-post-success"
      );
    }, 1000);
  }

  updateProgress(oEvent) {
    if (oEvent.lengthComputable) {
      var progress = oEvent.loaded / oEvent.total;
      alert(progress);
    } else {
      // Unable to compute progress information since the total size is unknown
    }
  }

  createUserFileUploadLog(status, size) {
    let postParams = {
      user_id: this.state.campaign_details[0].user_id,
      file_type: "video",
      location: "",
      file_size: size,
      upload_time: this.state.uploadTimer,
      platform_details: platform,
      platform_short_desc: platform.description,
      status: status,
    };

    ApiService.postData("createUserFileUploadLog", postParams, true).then(
      (response) => {
        if (response.status == "success") {
          // this.setState({
          //   isLoading: false,
          // });
          this.finishUpload();
          // this.props.history.push(
          //   "/" +
          //     this.props.match.params["event_name"] +
          //     "/" +
          //     this.props.match.params["campaign_name"] +
          //     "/video-post-success"
          // );
        } else {
          this.setState({ is_loading: false });
          this.props.history.push(
            "/" +
              this.props.match.params["event_name"] +
              "/" +
              this.props.match.params["campaign_name"] +
              "/error"
          );
        }
      }
    );
  }

  render() {
    let loaderMsg = "";

    let loaderImg = "";
    if (this.state.uploadTimer > 90 && this.state.uploadTimer < 210) {
      loaderMsg =
        "Looks like your internet is a bit slow. Please wait while we upload the video";
    } else if (this.state.uploadTimer > 210) {
      loaderMsg =
        "Uploading the video is taking more time than expected. Please wait a bit more";
    } else {
      loaderMsg = "Please wait while we upload the video";
    }

    if (this.state.isSingleUpload) {
      loaderImg = (
        <img
          src={loaderNormal}
          alt="loading..."
          className="fileUploadLoaderImg"
        />
      );
    } else {
      loaderImg = (
        <ProgressBar
          completed={this.state.uploadPercentage}
          bgColor="#cd9200"
          height="15px"
          labelColor="#fdfdfd"
          labelSize="10px"
        />
      );
    }

    return (
      <div className="postOuter">
        <div className="headerRegisteredOuter">
          {!this.state.isPublished ? (
            <Link
              to={
                "/" +
                this.props.match.params["event_name"] +
                "/" +
                this.props.match.params["campaign_name"] +
                "/home"
              }
            >
              <BsArrowLeft className="backBtn" />
            </Link>
          ) : (
            <Link
              to={
                "/my-profile?event_name=" +
                this.props.match.params["event_name"] +
                "&campaign_name=" +
                this.props.match.params["campaign_name"]
              }
            >
              <HomeIcon className="backBtn" />
            </Link>
          )}

          <h4 className="headerRegisteredTitle"></h4>
        </div>
        <div className="eventRegisteredOuter">
          {/* <h3 className="eventRegisteredTitle">That’s Great! </h3> */}
          <h3 className="eventRegisteredTitle">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit
          </h3>
          <p className="eventRegisteredDetails">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
          </p>
        </div>

        <div className="createPostSection">
          <p className="createPostLabel">Upload your video</p>

          <div className="upload-btn-wrapper">
            {this.state.sourceFile == "" ? (
              <>
                <div className="uploadBtn">
                  <img
                    alt="Upload"
                    className="uploadImg"
                    src={require("../../assets/images/post/upload.svg")}
                  />
                  <div>Tap to upload your video</div>
                </div>
                <input
                  ref="file"
                  type="file"
                  name="video-file"
                  multiple="false"
                  onChange={this.onChange.bind(this)}
                />
              </>
            ) : (
              <>
                <video
                  className="video-preview"
                  width="320"
                  height="240"
                  controls
                >
                  Your browser does not support the video tag.
                </video>
                <p
                  className="file-upload-notice"
                  style={{
                    marginBottom: 20,
                    width: 247,
                    fontFamily: "Montserrat-Regular",
                  }}
                >
                  * Video upload can take upto 5 min depeneding upon your
                  network connection. For better performance use WiFi network
                </p>

                <div className="btnAndInfoSection">
                  {!this.state.isLoading ? (
                    <Button
                      className="btnUploadVideo"
                      size="large"
                      onClick={() => this.initiateUpload()}
                    >
                      Submit video
                    </Button>
                  ) : (
                    <>
                      {/* <p className="eventRegisteredTitle"> { (this.state.uploadPercentage > 0) ? 'Uploaded '+this.state.uploadPercentage+'%' : 'File upload starting'}</p> */}

                      <ProgressBar
                        className="progressBar"
                        completed={this.state.uploadPercentage}
                        bgColor="#cd9200"
                        height="15px"
                        labelColor="#fdfdfd"
                        labelSize="10px"
                      />
                      <p
                        className="eventRegisteredTitle"
                        style={{
                          marginTop: 15,
                          width: 247,
                          fontFamily: "Montserrat-Medium",
                        }}
                      >
                        Please wait while we upload your video.
                      </p>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        <div className="clearBoth"></div>
      </div>
    );
  }
}
export default withRouter(CreateVideoPost);
