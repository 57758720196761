import React,{useState,useEffect} from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Checkbox from '@mui/material/Checkbox';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const ImageBox =({image,selectedImage,item, onSelect,onDelete})=>{
    const [isLoading, setIsLoading] = React.useState(true);
    const [selected, setSelected] = React.useState(selectedImage);

return (<ImageListItem key={image} >
    <img
      data-item-id={item.id}
      src={`${image}?w=248&fit=crop&auto=format`}
      srcSet={`${image}?w=248&fit=crop&auto=format&dpr=2 2x`}
      alt={item.title}
      loading="eager"
    />
    <ImageListItemBar           
      actionIcon={
            onDelete &&
            <IconButton
            onClick={()=>{ onSelect(!selected);setSelected(!selected);}}
            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
          >
           <DeleteOutlineIcon onClick={()=>{onDelete(item.id)}}/>
          </IconButton> || <IconButton
            onClick={()=>{ onSelect(!selected);setSelected(!selected);}}
            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
          >
            {selected ? <CheckCircleIcon color='success'/> : <CheckCircleOutlineIcon/> }
          </IconButton>
         
      }
    />
  </ImageListItem>)
}
export default function ImageListing({onClick,images,onSelect,selectedImages=[],onDelete}) {
    const [listImages,setListImages] = useState([]);
    useEffect(()=>{

        const filteredArr = images.reduce((acc, current) => {
          const x = acc.find(item => item.id === current.id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        setListImages(filteredArr);
    },[images])


  return (
    <Box sx={{overflowY: 'scroll' }}>
    <ImageList  variant="masonry" cols={2} gap={8}>    
      {listImages.map((item) => {
          let selectedImage = selectedImages.filter(itemImage=>{
            return itemImage.id == item.id;
          })
        return(
            <ImageBox onDelete={onDelete} item={item} selectedImage={selectedImage.length} onSelect={(isSelected)=>{onSelect(item, isSelected)}} onClick={onClick} image={item.image}/>
        )
      })}
    </ImageList>
    </Box>
  );
}

const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    author: '@bkristastucchio',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
    author: '@rollelflex_graphy726',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
    author: '@helloimnik',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
    author: '@nolanissac',
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
    author: '@hjrc33',
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
    author: '@tjdragotta',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
    author: '@katie_wasserman',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
    author: '@silverdalex',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
    author: '@shelleypauls',
  },
  {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
    author: '@peterlaster',
  },
  {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
    author: '@southside_customs',
    cols: 2,
  },
];