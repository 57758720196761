import React from "react";
import "./style.css";
import { AiFillClockCircle } from "react-icons/ai";
import { FaMapMarkerAlt } from "react-icons/fa";
import ApiService from "../../services/ApiService";
import StorageService from "../../services/StorageService";

class Participant extends React.Component {
  constructor() {
    super();

    if (StorageService.getData("campaign_details")) {
      this.state = {
        users: [],
        totalCount: 0,
        isReady: false,
        campaign_details: JSON.parse(StorageService.getData("campaign_details")),
      };
    } else {
      this.state = {
        users: [],
        totalCount: 0,
        isReady: false,
      };
    }

  }

  componentDidMount() {
    if (this.state.campaign_details.length > 0)
      this.getRegisteredUsers(this.state.campaign_details[0].id);
  }

  getRegisteredUsers(campaignId) {
    let getParams = {
      campaign_id: campaignId,
    };
    ApiService.getData("getRegisteredUsers", getParams, true).then(
      (response) => {
        if (response.status == "success") {
          this.setState({ users: response.data.data });
          this.setState({ totalCount: response.data.total });
          this.setState({ isReady: true });

          //console.log(this.state.users);
        } else {
          console.log("error");
          if (response.message == "Token has expired") {
            StorageService.removeData("access_token");
            StorageService.removeData("campaign_details");
            StorageService.removeData("user_details");
            this.props.history.push(
              "/" +
              this.props.match.params["event_name"] +
              "/" +
              this.props.match.params["campaign_name"] +
              "/login"
            );
          } else {
            this.props.history.push(
              "/" +
              this.props.match.params["event_name"] +
              "/" +
              this.props.match.params["campaign_name"] +
              "/error"
            );
          }
        }
      }
    );
  }

  render() {
    return (
      <div>
        {this.state.isReady === true ? (
          <div>
            <h3 className="participantHeading">
              <span className="participantHeadingTxt">Recent Activity</span>
              <span className="participantCount">{this.state.totalCount}</span>
            </h3>
            {this.state.users.map((user) => (
              <div className="participantList">
                <img
                  src={require("../../assets/images/profile-icon.png")}
                  alt="User"
                  className="userProfileImg"
                />
                <ul className="partiDetailsUL">
                  <li>
                    <span className="participantName">{user.user.name}</span>
                  </li>
                  <li>
                    <span className="timeDetails">
                      <AiFillClockCircle className="partiDetailsIcon" />
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(user.created_at))}
                    </span>
                  </li>
                </ul>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}
export default Participant;
