import * as React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router";
import "./style.css";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import { styled } from '@material-ui/core/styles';

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});


class ParticipantCard2 extends React.Component {

    constructor(props) {
        super(props);
    }



    showVideoUploadPage(participant) {
        this.props.history.push({
            pathname: '/' + this.props.match.params["event_name"] + "/" + this.props.match.params["campaign_name"] + "/create-video-post",
            data: participant
        });
    }

    render() {
        return (

            <Paper className="participant-card2" sx={{ p: 2, margin: 'auto', maxWidth: 500, flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Avatar alt={this.props.data.name ? this.props.data.name : 'unknown'} secondary={this.props.data.district ? this.props.data.district : 'unknown'} src="./assets/images/chef-avatar.jpg" />
                        <Typography className="participant-age" variant="subtitle1" component="div">
                            {this.props.data.age ? this.props.data.age : 'unknown'}
                        </Typography>
                    </Grid>

                    <Grid item xs={8} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography className="participant-name" gutterBottom variant="subtitle1" component="div">
                                    {this.props.data.name ? this.props.data.name : 'unknown'}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {this.props.data.parent_name ? this.props.data.parent_name : 'unknown'}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {this.props.data.phone ? this.props.data.phone : 'unknown'}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {this.props.data.school_name ? this.props.data.school_name : 'unknown'}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                    {this.props.data.district ? this.props.data.district : 'unknown'}
                                </Typography>
                            </Grid>

                        </Grid>

                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withRouter(ParticipantCard2);