import { Constants } from "../../config";
import { Endpoint } from "../../config";
import StorageService from "../StorageService";
import { Redirect } from "react-router";
import React from "react";
import urlConfig from "../../config/url-config";
const axios = require("axios");

let tokenExpired = ["Token is invalid", "Token has expired"];


const onCLickLogout = () => {
  let campainData =  StorageService.getData("campaign_data")  ? JSON.parse(StorageService.getData("campaign_data")) : [];

  StorageService.removeData("access_token");
  StorageService.removeData("campaign_details");
  StorageService.removeData("user_details");
  StorageService.removeData("event_id");
  StorageService.removeData("selected_event");
  StorageService.removeData("selectedImages");
  StorageService.removeData("lowResImages");
  StorageService.removeData("bidNo");
  window.location.href = "/login"
};

const ApiService = {
  getData(key, params, isAuthRequired = false, urlAppendTxt = "") {
    try {
      let config = {};
      if (isAuthRequired == true) {
        config = {
          params: params,
          headers: {
            Authorization: `Bearer ${StorageService.getData("access_token")}`,
          },
        };
      } else {
        config = {
          params: params,
        };
      }
      return new Promise((resolve, reject) => {
        axios
          .get(this.generateApiUrl(key, urlAppendTxt), config)
          .then(function (response) {
            if(response?.data?.status =='error' &&  tokenExpired.includes(response?.data?.message)){
              onCLickLogout();
            }

            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
            // resolve(error.response.data);
            //window.location.href = "/error";
            // window.location.href = "/mahachef/mahachef-junior_1635337426/error";
          })
          .finally(function () {
            // always executed
          });
      }).catch((err) => console.log(err));
    } catch (error) {
      console.log('Error',error);

      //window.location.href = "/error";
      // window.location.href = "mahachef/mahachef-junior_1635337426/error";
    }
  },

  postData(key, params, isAuthRequired = false, urlAppendTxt = "") {
    console.log(this.generateApiUrl(key, urlAppendTxt), params);
    try {
      let config = {};
      if (isAuthRequired == true) {
        config = {
          headers: {
            Authorization: `Bearer ${StorageService.getData("access_token")}`,
          },
        };
      }
      return new Promise((resolve, reject) => {
        axios
          .post(this.generateApiUrl(key, urlAppendTxt), params, config)
          .then(function (response) {
            if(response?.data?.status =='error' && tokenExpired.includes(response?.data?.message)){
              onCLickLogout();
            }
            resolve(response.data);
          })
          .catch(function (error) {
            resolve(error.response.data);
            //window.location.href = "/error";
            // window.location.href = "/mahachef/mahachef-junior_1635337426/error";
          });
      }).catch((err) => console.log(err));
    } catch (error) {
      console.log('Error',error);
      //window.location.href = "/error";
      // window.location.href = "/mahachef/mahachef-junior_1635337426/error";
    }
  },
  imgConsoleGetData(urlMethod){
    let BASE_URL = urlConfig.isDevelopment() ? '/imageconsole' : urlConfig.imageConsole();
    let url = `${BASE_URL}/api/${urlMethod}`;
    // let config = {params:params}
    let config = {headers:{Authorization:'Token 5d1c0643f356cc365352c35d430e846de13e1040'}}
    return   axios
    .get(url,config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error; 
    });

  },
  imgConsolePostData(urlMethod,params){
    let BASE_URL = urlConfig.isDevelopment() ? '/imageconsole' : urlConfig.imageConsole();
    let url = `${BASE_URL}/api/${urlMethod}`;
    // let config = {params:params}
    let config = {headers:{Authorization:'Token 5d1c0643f356cc365352c35d430e846de13e1040'}}

    return   axios
    .post(url,params, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error; 
    });

  },

  frGetData(urlMethod){
    let BASE_URL = urlConfig.isDevelopment() ? '/imageconsole' : urlConfig.imageConsole();
    let url = `${BASE_URL}/api/images/${urlMethod}`;
    // let config = {params:params}
    let config = {headers:{Authorization:'Token 5d1c0643f356cc365352c35d430e846de13e1040'}}

    return   axios
    .get(url,config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error; 
    });

  },
  frPostData(urlMethod,params){
    let BASE_URL = urlConfig.isDevelopment() ? '/imageconsole' : urlConfig.imageConsole();
    let url = `${BASE_URL}/api/images/${urlMethod}`;
    // let config = {params:params}
    let config = {headers:{Authorization:'Token 5d1c0643f356cc365352c35d430e846de13e1040'}}

    return   axios
    .post(url,params,config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error; 
    });

  },

  getImage(link){
    console.log(link);
   let BASE_URL = Constants.DEVELOPMENT ? link.replace('https://dtq9cqmh745pp.cloudfront.net','/cloudfront') : link;
    // let config = {params:params}
    console.log("url", BASE_URL);
    return   axios
    .get(BASE_URL)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error; 
    });

  },

  generateApiUrl(method, urlAppendTxt) {
    let apiUrl = "";

    switch (method) {
      case "createImage":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.createImage +
          urlAppendTxt;
        break;
      case "googleLogin":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.googleLogin +
          urlAppendTxt;
        break;
      case "facebookLogin":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.facebookLogin +
          urlAppendTxt;
        break;

      case "getUserPosts":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.getUserPosts +
          urlAppendTxt;
        break;
      case "getRegisteredUsers":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.getRegisteredUsers +
          urlAppendTxt;
        break;
      case "getAllPosts":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.getAllPosts +
          urlAppendTxt;
        break;
      case "getCampaignTemplates":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.getCampaignTemplates +
          urlAppendTxt;
        break;
      case "getByCampaignFriendlyName":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.getByCampaignFriendlyName +
          urlAppendTxt;
        break;
      case "checkIfCampaignActive":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.checkIfCampaignActive +
          urlAppendTxt;
        break;
      case "createParticipantEnrollment":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.createParticipantEnrollment +
          urlAppendTxt;
        break;
      case "updateParticipantEnrolmentPayment":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.updateParticipantEnrolmentPayment +
          urlAppendTxt;
        break;
      case "getUserParticipants":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.getUserParticipants +
          urlAppendTxt;
        break;
      case "getAllParticipants":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.getAllParticipants +
          urlAppendTxt;
        break;
      case "addParticipantVideo":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.addParticipantVideo +
          urlAppendTxt;
        break;
      case "createUserFileUploadLog":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.createUserFileUploadLog +
          urlAppendTxt;
        break;
      case "getLowResPhotosByBidNo":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.getLowResPhotosByBidNo +
          urlAppendTxt;
        break;
      case "createPhotoOrder":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.createPhotoOrder +
          urlAppendTxt;
        break;
      case "getMyPhotos":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.getMyPhotos +
          urlAppendTxt;
        break;
      case "searchEvent":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.searchEvent +
          urlAppendTxt;
        break;
      case "getByEventFriendlyName":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.getByEventFriendlyName +
          urlAppendTxt;
        break;
      case "getCampaignImagesByName":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.getCampaignImagesByName +
          urlAppendTxt;
        break;
        case "getCompanies":
        apiUrl =
          Constants.API_CLASSIFICATION_URL +
          Endpoint.getCompanies +
          urlAppendTxt;
        break;
        case "completeProfile":
          apiUrl =
            Constants.API_CLASSIFICATION_URL +
            Endpoint.completeProfile +
            urlAppendTxt;
          break;  
          case "getCertificateTemplate":
            apiUrl =
              Constants.API_CLASSIFICATION_URL +
              Endpoint.getCertificateTemplate +
              urlAppendTxt;
            break;       
          default:
            apiUrl =
            Constants.API_CLASSIFICATION_URL +
            Endpoint[method] +
            urlAppendTxt;
          break;
    }

    return apiUrl;
  },
};

export default ApiService;
