import React from "react";
import "./style.css";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withRouter } from "react-router";
import StorageService from "../../services/StorageService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

class CustomerRegistration extends React.Component {

  constructor(props) {
    super(props);

    let arr = JSON.parse(StorageService.getData('participant_arr'));
    // console.log(arr);    

    if (arr && arr.length > 0) {
      // this.populateData(arr[0]);
      this.state = {
        ticket_count: StorageService.getData('ticket_count'),
        current_entry_no: 1,
        name: arr[0]['name'],
        phone: arr[0]['phone'],
        age: arr[0]['age'],
        dob: new Date(arr[0]['dateOfBirth']),
        dateOfBirth: arr[0]['dateOfBirth'],
        parent_name: arr[0]['parent_name'],
        district: arr[0]['district'],
        school_name: arr[0]['school_name'],
        participantArr: (arr.length > 0) ? arr : [],
      }
    } else {
      this.state = {
        ticket_count: StorageService.getData('ticket_count'),
        current_entry_no: 1,
        name: '',
        phone: '',
        age: '',
        dob: '',
        dateOfBirth: '',
        parent_name: '',
        district: '',
        school_name: '',
        participantArr: [],
      }
    }
  }

  componentDidMount(){
    if(StorageService.getData('ticket_count') < 1){
      this.props.history.push(
        "/" +
        this.props.match.params["event_name"] +
        "/" +
        this.props.match.params["campaign_name"] +
        "/home"
      );
    }
  }

  viewEventDetailPage() {
    // this.props.history.push("/event");
    this.props.history.push(
      "/" +
      this.props.match.params["event_name"] +
      "/" +
      this.props.match.params["campaign_name"] +
      "/registration-payment"
    );
  }

  setDob(date) {
    this.getAge(date);
    let dateOfBirthVal = moment(date).format('YYYY-MM-DD');

    this.setState({ dob: date });
    this.setState({ dateOfBirth: dateOfBirthVal });
  }

  getAge(dateString){
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age--;
    }
    this.setState({ age: age });
  }

  onClickNext() {

    if (
      this.state.name != "" &&
      this.state.phone != "" &&
      this.state.age != "" &&
      this.state.dateOfBirth != "" &&
      this.state.parent_name != "" &&
      this.state.district != "" &&
      this.state.school_name != ""
    ) {

      if (this.state.phone.length != 10) {
        alert('Please enter a valid phone number');
        return;
      }

      let temp = {
        name: this.state.name,
        phone: this.state.phone,
        age: this.state.age,
        dateOfBirth: this.state.dateOfBirth,
        parent_name: this.state.parent_name,
        district: this.state.district,
        school_name: this.state.school_name
      }


      let arr = this.state.participantArr;
      arr[this.state.current_entry_no - 1] = temp;
      this.setState({ participantArr: arr });

      StorageService.storeData('participant_arr', JSON.stringify(arr));

      if (this.state.current_entry_no < this.state.ticket_count) {
        this.state.current_entry_no = this.state.current_entry_no + 1;

        if (this.state.participantArr[this.state.current_entry_no - 1]) {
          this.populateData(this.state.participantArr[this.state.current_entry_no - 1]);
        } else {
          this.resetForm();
        }

        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        
      } else {
        this.props.history.push(
          "/" +
          this.props.match.params["event_name"] +
          "/" +
          this.props.match.params["campaign_name"] +
          "/registration-payment"
        );
      }


    } else {
      alert('Please fill participants details');
    }

  }

  onClickPrev() {
    if (this.state.current_entry_no > 1) {
      this.state.current_entry_no = this.state.current_entry_no - 1;
      if (this.state.participantArr[this.state.current_entry_no - 1]) {
        this.populateData(this.state.participantArr[this.state.current_entry_no - 1]);
      }
    }
  }

  resetForm() {
    this.setState({ name: "" });
    this.setState({ phone: "" });
    this.setState({ age: "" });
    this.setState({ dob: "" });
    this.setState({ dateOfBirth: "" });
    this.setState({ parent_name: "" });
    this.setState({ district: "" });
    this.setState({ school_name: "" });
  }

  populateData(data) {
    this.setState({ name: data['name'] });
    this.setState({ phone: data['phone'] });
    this.setState({ age: data['age'] });
    this.setState({ parent_name: data['parent_name'] });
    this.setState({ district: data['district'] });
    this.setState({ school_name: data['school'] });
    this.setState({ dob: new Date(data['dateOfBirth']) });
    this.setState({ dateOfBirth: data['dateOfBirth'] });

  }

  goBack() {
    this.props.history.push(
      "/" +
      this.props.match.params["event_name"] +
      "/" +
      this.props.match.params["campaign_name"] +
      "/registration-ticket"
    );
  }

  render() {
    return (
      <div id="cust_reg_container" className="main-container">
        <div id="header">
          <nav id="back" onClick={() => this.goBack()}></nav>
          <h1 id="page-title">Participant {this.state.current_entry_no} of {this.state.ticket_count}</h1>
        </div>

        <div id="content-area">

          <section id="name" className="full-width">
            <div className="row sub_title_form">
              <label>Full name</label>
            </div>
            <div className="row align-center">
              <TextField
                fullWidth
                id="outlined-username-input"
                label="Name"
                type="text"
                variant="outlined"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </div>
          </section>

          <section id="phone" className="full-width">
            <div className="row sub_title_form">
              <label>Phone</label>
            </div>
            <div className="row align-center">
              <TextField
                fullWidth
                id="outlined-phone-input"
                label="Phone"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                variant="outlined"
                value={this.state.phone}
                onChange={(e) => this.setState({ phone: e.target.value })}
              />
            </div>
          </section>

          <section id="dob" className="full-width">
            <div className="row sub_title_form">
              <label>Date of birth</label>
            </div>
            <div className="row align-center">
              <DatePicker
                className="datePicker"
                label="Date of birth"
                variant="outlined"
                showYearDropdown
                showMonthDropdown
                peekNextMonth
                dropdownMode="select"
                selected={this.state.dob} onChange={(date) => this.setDob(date)}
                minDate={new Date("2008/01/01")}
                maxDate={new Date("2013/12/31")}
              />
            </div>
          </section>


          <section id="age" className="full-width">
            <div className="row sub_title_form">
              <label>Age</label>
            </div>
            <div className="row align-center">
              <TextField
                fullWidth
                id="outlined-age-input"
                label="Enter your age"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                variant="outlined"
                value={this.state.age}
                onChange={(e) => this.setState({ age: e.target.value })}
              />
            </div>
          </section>

          <section id="parent" className="full-width">
            <div className="row sub_title_form">
              <label>Parent Name</label>
            </div>
            <div className="row align-center">
              <TextField
                fullWidth
                id="outlined-parent_name-input"
                label="Parent Name"
                type="text"
                value={this.state.parent_name}
                variant="outlined"
                onChange={(e) => this.setState({ parent_name: e.target.value })}
              />
            </div>
          </section>

          <section id="district" className="full-width">
            <div className="row sub_title_form">
              <label>District</label>
            </div>
            <div className="row align-center" >
              <Select
                labelId="district-select-label"
                id="district-select"
                label="District"
                style={{ width: '100%' }}
                value={this.state.district}
                onChange={(e) => this.setState({ district: e.target.value })}
              >
                <MenuItem value={"Ahmednagar"} > Ahmednagar </MenuItem>
                <MenuItem value={"Akola"}> Akola </MenuItem>
                <MenuItem value={"Amravati"}> Amravati </MenuItem>
                <MenuItem value={"Aurangabad"}> Aurangabad </MenuItem>
                <MenuItem value={"Beed"}> Beed </MenuItem>
                <MenuItem value={"Bhandara"}> Bhandara </MenuItem>
                <MenuItem value={"Buldhana"}> Buldhana </MenuItem>
                <MenuItem value={"Chandrapur"}> Chandrapur </MenuItem>
                <MenuItem value={"Dhule"}> Dhule </MenuItem>
                <MenuItem value={"Gadchiroli"}> Gadchiroli </MenuItem>
                <MenuItem value={"Gondia"}> Gondia </MenuItem>
                <MenuItem value={"Hingoli"}> Hingoli </MenuItem>
                <MenuItem value={"Jalgaon"}> Jalgaon </MenuItem>
                <MenuItem value={"Jalna"}> Jalna </MenuItem>
                <MenuItem value={"Kolhapur"}> Kolhapur </MenuItem>
                <MenuItem value={"Latur"}> Latur </MenuItem>
                <MenuItem value={"Mumbai City"}> Mumbai City </MenuItem>
                <MenuItem value={"Mumbai Subarban"}> Mumbai Subarban </MenuItem>
                <MenuItem value={"Nagpur"}> Nagpur </MenuItem>
                <MenuItem value={"Nanded"}> Nanded </MenuItem>
                <MenuItem value={"Nandurbar"}> Nandurbar </MenuItem>
                <MenuItem value={"Nashik"}> Nashik </MenuItem>
                <MenuItem value={"Osmanabad"}> Osmanabad </MenuItem>
                <MenuItem value={"Palghar"}> Palghar </MenuItem>
                <MenuItem value={"Parbhani"}> Parbhani </MenuItem>
                <MenuItem value={"Pune"}> Pune </MenuItem>
                <MenuItem value={"Raigad"}> Raigad </MenuItem>
                <MenuItem value={"Ratnagiri"}> Ratnagiri </MenuItem>
                <MenuItem value={"Sangli"}> Sangli </MenuItem>
                <MenuItem value={"Satara"}> Satara </MenuItem>
                <MenuItem value={"Sindhudurg"}> Sindhudurg </MenuItem>
                <MenuItem value={"Solapur"}> Solapur </MenuItem>
                <MenuItem value={"Thane"}> Thane </MenuItem>
                <MenuItem value={"Wardha"}> Wardha </MenuItem>
                <MenuItem value={"Washim"}> Washim </MenuItem>
                <MenuItem value={"Yavatmal"}> Yavatmal </MenuItem>

              </Select>
            </div>
          </section>

          <section id="school" className="full-width">
            <div className="row sub_title_form">
              <label>School Name</label>
            </div>
            <div className="row align-center">
              <TextField
                fullWidth
                id="outlined-parent_name-input"
                label="School Name"
                type="text"
                variant="outlined"
                value={this.state.school_name}
                onChange={(e) => this.setState({ school_name: e.target.value })}
              />
            </div>
          </section>


        </div>
        <div id="footerBtnSection">

        {(this.state.current_entry_no == 1) ?
          <Button
            className="btn-prev-disabled"
            variant="outlined"
            color="primary"           
          >
            Previous
          </Button>

          :
          <Button
            className="btn-prev"
            variant="outlined"
            color="primary"
            onClick={() => this.onClickPrev()}
          >
            Previous
          </Button>

        }
          

          <Button
            className="btn-next"
            size="large"
            onClick={() => this.onClickNext()}
          >
            Next
          </Button>
        </div>
      </div>
    );
  }
}
export default withRouter(CustomerRegistration);
