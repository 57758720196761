const StorageService = {
  storeData(key, value) {
    localStorage.setItem(key, value);
  },

  getData(key) {
    const result = localStorage.getItem(key);
    return result;
  },

  removeData(key) {
    localStorage.removeItem(key);
  },
};

export default StorageService;
