import React,{ useEffect} from "react";
import Camera from 'react-camera';
import {AiOutlineCamera} from 'react-icons/ai'
import {BsImages} from 'react-icons/bs'
import {MdReplay, MdOutlineNavigateNext} from 'react-icons/md'
import {GrNext} from 'react-icons/gr'
import { useFilePicker } from 'use-file-picker';


const PicFromGallery = ({onSelect})=> {
  const [openFileSelector, { filesContent, loading }] = useFilePicker({
    accept: 'image/*',
    multiple:false,
    readAs:'DataURL',
  });
  
  useEffect(()=>{
    if(filesContent.length > 0){
      onSelect(filesContent[0].content);
    }
  },[filesContent]);

  return(
    <BsImages  onClick={() => openFileSelector()} style={{position:'absolute',color:'#fff', right:0}} size={30}/>
  )
}
export default class CameraModule extends React.Component
{
   
  constructor(props) {
    super(props);
    this.takePicture = this.takePicture.bind(this);
    this.state ={
        isPhotoTaken:false,
        type:'camera',
        image:null,
    }
  }

  takePicture() {
    this.camera.capture()
    .then(blob => {
      this.img.src = URL.createObjectURL(blob);
      this.img.onload = () => { URL.revokeObjectURL(this.src); }
      this.setState({isPhotoTaken:true,type:'camera', image:blob});
    })
  }

  render() {
    return (
      <div style={style.container}>
         <img
          style={style.captureImage}
          ref={(img) => {
            this.img = img;
          }}
        />
        <Camera        
          style={style.preview}
          ref={(cam) => {
            this.camera = cam;
          }}
        />
        {/* {console.log( this.img.src)} */}

        {
            !this.state.isPhotoTaken &&

            <div style={style.captureContainer} >
            <div style={style.captureButton} onClick={this.takePicture}>
                <AiOutlineCamera size={30}/>
                </div>
                <PicFromGallery onSelect={(image)=>{
                  this.props.upload('gallery', image)
                }}/>
          </div> ||

                <div style={style.captureContainer} >
                <div style={style.captureButton} onClick={()=>{this.img.src = ""; ;this.setState({isPhotoTaken:false})}}>
                    <MdReplay size={30}/>
                    </div>
                    <div style={style.captureButtonGo} onClick={()=>this.props.upload(this.state.type, this.state.image)} >
                    <GrNext size={30}/>
                    </div>
                </div>

        }
       

         
      </div>
    );
  }
}

const style = {
    container: {
      position: 'relative',
      height:'100vh',

    },
    preview:{
        position:'absolute',
        top:80,
    },
    captureContainer: {
      display: 'flex',
      position: 'absolute',
      justifyContent: 'center',
      alignItems:'center',
      zIndex: 1,
      bottom: 20,
      width: '100%'
    },
    captureButton: {
      backgroundColor: '#fff',
      borderRadius: '50%',
      height: 56,
      width: 56,
      color: '#000',
      margin: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    captureButtonGo: {
        backgroundColor: '#fff',
        borderRadius: '50%',
        height: 56,
        width: 56,
        color: '#000',
        margin: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position:'absolute',
        right:0,
        
      },
    captureImage: {
      width: '100%',
      position:'absolute',
      top:80,
      zIndex:999,
    }
  };