import React from "react";
import "./style.css";
import { withRouter } from "react-router";
import LeftDrawer from "../../components/leftDrawer";
import StorageService from "../../services/StorageService";
import Grid from "@material-ui/core/Grid";
import {
  AiOutlineCloseCircle,
  AiOutlineClose,
  AiOutlineCloseSquare,
} from "react-icons/ai";

import { Constants } from "../../config";
import ApiService from "../../services/ApiService";
import DownloadPostButton from "../../components/DownloadPostButton";
import { BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";

const __DEV__ = Constants.__DEV__;
const SERVERPATH = Constants.SERVERPATH;
const imgPath = Constants.IMAGE_PATH;

class DownloadPostSummary extends React.Component {
  constructor(props) {
    super(props);

    let user_details = JSON.parse(StorageService.getData("user_details"));
    let selectedImages = StorageService.getData("selectedImages")
      ? JSON.parse(StorageService.getData("selectedImages"))
      : [];

    this.state = {
      selectedImages: selectedImages,
      totalPrice: 0,
      is_loading: false,
      loader_message: "",
      user_name: user_details["firstName"] + " " + user_details["lastName"],
      user_email: user_details["email"],
      event_details: JSON.parse(StorageService.getData("selected_event")),
    };

    if (selectedImages.length < 1) {
      this.props.history.push(
        "/" +
          this.props.match.params["event_name"] +
          "/" +
          this.props.match.params["campaign_name"] +
          "/find-your-photo"
      );
    }
  }

  removeImage(index) {
    let selectedImages = this.state.selectedImages;
    if (typeof selectedImages[index] != "undefined")
      selectedImages.splice(index, 1);
    this.setState({ selectedImages: selectedImages });
    this.calculateTotal();
    StorageService.storeData(
      "selectedImages",
      JSON.stringify(this.state.selectedImages)
    );
  }

  calculateTotal() {
    let total = 0;
    if (this.state.event_details.photo_price_type_id == 1)
      // Free
      total = 0;
    else if (this.state.event_details.photo_price_type_id == 2)
      // Flat rate
      total = this.state.event_details.photo_price;
    else {
      // Cost per photo
      this.state.selectedImages &&
        this.state.selectedImages.length > 0 &&
        this.state.selectedImages.map((item, index) => {
          total = total + item.price;
        });
    }

    this.setState({ totalPrice: total });
  }

  goToSuccessPage = () => {
    this.props.history.push(
      "/" +
        this.props.match.params["event_name"] +
        "/" +
        this.props.match.params["campaign_name"] +
        "/download-posts-success"
    );
  };

  loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  componentDidMount() {
    if (this.state.selectedImages.length > 0) {
      this.calculateTotal();
    }
  }

  async displayRazorpay() {
    this.setState({
      loader_message: "Please wait while we intiate the payment procedure",
    });
    this.setState({ is_loading: true });

    const stateVal = this.state;

    const res = await this.loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    let postParams = {
      total_price: this.state.totalPrice,
    };

    const data = await fetch(SERVERPATH + "/posts-razorpay", {
      method: "POST",
      body: JSON.stringify(postParams),
      headers: { "Content-Type": "application/json" },
    }).then((t) => t.json());

    const options = {
      // key: __DEV__ ? Constants.RAZORPAY_KEY_DEV : Constants.RAZORPAY_KEY_PROD,
      key: Constants.RAZORPAY_KEY,
      currency: data.currency,
      amount: data.amount.toString(),
      order_id: data.id,
      name: StorageService.getData("event_title")
        ? StorageService.getData("event_title")
        : "Eventforce",
      description: "Thank you",
      // image: imgPath + "/logo.dff71fb7.png",
      image: StorageService.getData("event_logo"),
      handler: (response) => {
        if (response) {
          this.setState({
            loader_message: "Please wait while we prepare your photos",
          });

          let imagesArr = this.state.selectedImages.map((a) => a.id);

          const paymentDetails = {
            amount: data.amount.toString(),
            gateway: "razorpay",
            transaction_id: response.razorpay_signature,
            order_id: response.razorpay_order_id,
            payment_id: response.razorpay_payment_id,
            gateway_response: response,
            status: 1,
          };

          let postParams = {
            payment_gateway_response: JSON.stringify(paymentDetails),
            status: 1,
            transaction_id: response.razorpay_signature,
            images: JSON.stringify(imagesArr),
            total_qty: this.state.selectedImages.length,
            total_price: this.state.totalPrice,
          };
          ApiService.postData("createPhotoOrder", postParams, true).then(
            (response) => {
              if (response.status == "success") {
                let search = window.location.search;
                let params = new URLSearchParams(search);
                let app_type = params.get("app_type");
                let event = params.get("event");

                StorageService.removeData("selectedImages");
                StorageService.removeData("bidNo");

                this.props.history.push(
                  event != null && event != ""
                    ? "/download-posts-success?app_type=" +
                        app_type +
                        "&event=" +
                        event
                    : "/download-posts-success?app_type=" + app_type
                );
              } else {
              }
            }
          );
        }
      },
      modal: {
        ondismiss: () => {
          alert("Payment cancelled. Try again");
          this.setState({ is_loading: false });
        },
      },
      prefill: {
        name: this.state.user_name,
        email: this.state.user_email,
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  render() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let app_type = params.get("app_type");
    let event = params.get("event");

    let backLink = "";

    if (event && event != "")
      backLink = "/find-your-photo?app_type=" + app_type + "&event=" + event;
    else backLink = "/find-your-photo?app_type=" + app_type;

    return (
      <div className="pageOuter">
        <div className="privacyGreenBlock">
          <div className="summaryHeaderOuter">
            <Link to={backLink}>
              <BsArrowLeft className="backBtn" />
            </Link>
            <h4 className="headerTitle"></h4>
          </div>
          <div className="ppOuter">
            <h4 className="ppTitle">Summary</h4>
            <h3 className="ppTitle"></h3>
          </div>
        </div>
        <div className="orderSummaryOuter">
          {this.state.selectedImages &&
            this.state.selectedImages.length > 0 &&
            this.state.selectedImages.map((item, index) => (
              <Grid container spacing={2} className="imageOuterGrid">
                <Grid item xs={5} className="columnGrid">
                  <img src={item.src} alt="Image" className="orderListImage" />
                </Grid>

                <Grid item xs={5} className="columnGrid">
                  {this.state.event_details.photo_price_type_id != 1 &&
                    this.state.event_details.photo_price_type_id != 2 && (
                      <p className="imagePrice">x ₹{item.price}</p>
                    )}
                </Grid>
                <Grid item xs={2} className="columnGrid">
                  <a
                    className="imageDeleteLink"
                    onClick={() => this.removeImage(index)}
                  >
                    {/* <AiOutlineCloseCircle className="imageDeleteIcon" /> */}
                    <AiOutlineCloseSquare className="imageDeleteIcon" />
                  </a>
                </Grid>
              </Grid>
            ))}
        </div>

        <DownloadPostButton
          count={this.state.selectedImages.length}
          totalPrice={this.state.totalPrice}
          onClickDownloadButton={() => this.displayRazorpay()}
          endText={"Purchase"}
        />
      </div>
    );
  }
}
export default withRouter(DownloadPostSummary);
