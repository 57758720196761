import React from "react";
import Modal from 'react-modal';
import { Button } from "@material-ui/core";


const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: '80%',
      transform: 'translate(-50%, -50%)',
      // height:'250px',
    },
  };


  
  const ModalBox =({title, confirm, cancel, isOpen, des})=>{
    return(
        <Modal
        isOpen={isOpen}       
        contentLabel="Example Modal"
        style={customStyles}
      >      
      <h6>{title}</h6>
      <p >{des}</p>

      <Button color="primary"  onClick={confirm}>
        <p >Confirm</p>
        </Button>

        <Button  onClick={cancel}>
        <p >Cancel</p>
        </Button>
    </Modal>
    )
  }

  export default ModalBox;