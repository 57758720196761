import React from "react";
import "./style.css";
import { withRouter } from "react-router";

import LeftDrawer from "../../components/leftDrawer";
import AuthenticationService from "../../services/AuthenticationService";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import Gallery from "react-photo-gallery";
import ApiService from "../../services/ApiService";
import Button from "@material-ui/core/Button";

const _ = require("lodash");

class AllPostsGallery extends React.Component {
  constructor(props) {
    super(props);

    document.body.style.maxWidth = "100%";
    document.body.style.position = null;

    this.state = {
      photos: [],
      campaings: [],
      isApiEnd: false,
      totalCamps: 0,
      currentPgNo: 1,
    };

    this.getCampaignPosts(1);
  }

  getCampaignPosts(pgNo) {
    this.setState({ isLoading: true });
    let getParams = {
      page: pgNo,
      pageCount: 10,
      friendly_url: this.props.match.params["campaign_name"],
    };
    ApiService.getData("getCampaignImagesByName", getParams, true).then(
      (response) => {
        if (response.status == "success") {
          this.setState({
            campaings: [...this.state.campaings, ...response.data.data],
            currentPgNo: pgNo,
          });
          this.setState({ totalCamps: response.data.total });
          setTimeout(() => {
            this.setState({ isLoading: false });
          }, 2000);

          if (response.data.data.length < 1) {
            this.setState({ isApiEnd: true });
          }
        } else {
          console.log("error");
        }
      }
    );
  }

  render() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let title = params.get("title");

    let loginPath =
      "/" +
      this.props.match.params["event_name"] +
      "/" +
      this.props.match.params["campaign_name"] +
      "/login";
    return (
      <div className="pageOuter">
        <div className="headerGreenBlock">
          <div className="headerOuter">
            {AuthenticationService.isUserLoggedIn() === true ? (
              <LeftDrawer></LeftDrawer>
            ) : (
              <Link to={loginPath}>
                <HomeIcon className="backBtn" />
              </Link>
            )}

            <h4 className="campWallTitle">Campaign Wall</h4>
            {title && title != "" && title != "" && (
              <p className="campWallTeam">{title}</p>
            )}
          </div>
        </div>
        <div className="campaignPostBlock">
          {this.state.campaings.length > 0 && (
            <Gallery photos={this.state.campaings} />
          )}

          <div className="clr"></div>

          {this.state.isLoading === true && this.state.isApiEnd === false ? (
            <div className="campaignLoader">
              <img
                src={require("./../../assets/images/velo-logo.png")}
                alt="Logo loader"
                className="campLoadingImg"
              />
              <span className="campLoadingTxt">Loading...</span>
            </div>
          ) : null}

          {this.state.isLoading !== true && this.state.isApiEnd === false ? (
            <div className="loadBtnOuter">
              <Button
                className="loadBtn"
                onClick={() =>
                  this.getCampaignPosts(this.state.currentPgNo + 1)
                }
              >
                Load More
              </Button>
            </div>
          ) : null}

          {this.state.isApiEnd === true ? (
            <div className="loadBtnOuter">
              <span className="campLoadingTxt">You have reached the end !</span>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
export default withRouter(AllPostsGallery);
