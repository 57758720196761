import * as React from 'react';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { withRouter } from "react-router";
import "./style.css";


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';



const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);


class ParticipantList2 extends React.Component {

    constructor(props) {
        super(props);
    }

    showVideoUploadPage(participant) {
        this.props.history.push({
            pathname: '/' + this.props.match.params["event_name"] + "/" + this.props.match.params["campaign_name"] + "/create-video-post",
            data: participant
        });
    }
   

    render() {
        return (
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {
                    this.props.hide_button ?
                        <ListItem >
                            <ListItemAvatar>
                                <Avatar alt={this.props.data.name ? this.props.data.name : 'unknown'} secondary={this.props.data.district ? this.props.data.district : 'unknown'} src="./assets/images/chef-avatar.jpg" />                                
                            </ListItemAvatar>
                            <ListItemText primary={this.props.data.name ? this.props.data.name : 'unknown'} secondary={this.props.data.district ? this.props.data.district : 'unknown'} />
                        </ListItem>
                    :
                        this.props.data.videoSubmitted === true ?
                            <ListItem >
                                <ListItemAvatar>
                                    <Avatar alt={this.props.data.name ? this.props.data.name : 'unknown'} secondary={this.props.data.district ? this.props.data.district : 'unknown'} src="./assets/images/chef-avatar.jpg" />
                                    <Chip className="chip-video-submitted" label="Submitted" />
                                </ListItemAvatar>
                                <ListItemText primary={this.props.data.name ? this.props.data.name : 'unknown'} secondary={this.props.data.district ? this.props.data.district : 'unknown'} />
                            </ListItem>
                        :
                            <ListItem button onClick={() => this.showVideoUploadPage(this.props.data)} >
                                <ListItemAvatar>
                                    <Avatar alt={this.props.data.name ? this.props.data.name : 'unknown'} secondary={this.props.data.district ? this.props.data.district : 'unknown'} src="./assets/images/chef-avatar.jpg" />
                                    <Chip className="chip-video-pending" label="Upload" />
                                </ListItemAvatar>
                                <ListItemText primary={this.props.data.name ? this.props.data.name : 'unknown'} secondary={this.props.data.district ? this.props.data.district : 'unknown'} />
                            </ListItem>
                
                }

               

            </List>
        );
    }
}

export default withRouter(ParticipantList2);