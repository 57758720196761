import React, { useRef } from "react";
import "./style.css";
import { BsArrowLeft } from "react-icons/bs";
import { withRouter } from "react-router";
import ImageCropper from "../../components/cropper/imagecropper";
import HomeIcon from "@material-ui/icons/Home";
import { Link } from "react-router-dom";
import StorageService from "../../services/StorageService";
import AuthenticationService from "../../services/AuthenticationService";

class CreatePost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPublished: false,
      showPublishBtn: false,
    };

    AuthenticationService.isCampaignActive(
      this.props.match.params["campaign_name"],
      this.props.match.params["event_name"]
    );
  }

  goHome() {
    // this.props.history.push("/profile");
    this.props.history.push(
      "/" +
        this.props.match.params["event_name"] +
        "/" +
        this.props.match.params["campaign_name"] +
        "/profile"
    );
  }

  onPublished() {
    this.setState({
      isPublished: true,
    });
  }

  goToPrivacyPage() {
    // this.props.history.push(
    //   "/" +
    //   this.props.match.params["event_name"] +
    //   "/" +
    //   this.props.match.params["campaign_name"] +
    //   "/privacy-policy"
    // );
    window.open(
      "/" +
        this.props.match.params["event_name"] +
        "/" +
        this.props.match.params["campaign_name"] +
        "/privacy-policy",
      "_blank"
    );
  }


  render() {
    return (
      <div className="postOuter">
        <div className="headerRegisteredOuter">
          {!this.state.isPublished ? (
            <Link
              to={
                "/" +
                this.props.match.params["event_name"] +
                "/" +
                this.props.match.params["campaign_name"] +
                "/home"
              }
            >
              <BsArrowLeft className="backBtn" />
            </Link>
          ) : (
            <Link
              to={
                "/my-profile?event_name=" +
                this.props.match.params["event_name"] +
                "&campaign_name=" +
                this.props.match.params["campaign_name"]
              }
            >
              <HomeIcon className="backBtn" />
            </Link>
          )}

          <h4 className="headerRegisteredTitle"></h4>
        </div>
        <div className="eventRegisteredOuter">
          <h3 className="eventRegisteredTitle">That’s Great! </h3>
          <h3 className="eventRegisteredDetails">Here are the steps</h3>
          <h3 className="eventRegisteredDetails">
            1) Choose the template of your choice by scrolling right or left.{" "}
          </h3>
          <h3 className="eventRegisteredDetails">
            2) Take a selfie / upload image{" "}
          </h3>
          <h3 className="eventRegisteredDetails">3) Publish your post </h3>
          <h3 className="eventRegisteredDetails">
            4) Share your image and download your original from profile page.{" "}
          </h3>

          <p className="advertText">An experience by eventforce.ai</p>
        </div>

        <div className="createPostSection">
          {/* <p className="createPostLabel">Create your post</p> */}

          <ImageCropper
            goToPrivacyPage={() => this.goToPrivacyPage()}
            onPublished={() => this.onPublished()}
          ></ImageCropper>
        </div>
        <div className="clearBoth"></div>
      </div>
    );
  }
}
export default withRouter(CreatePost);
