import React from "react";
import "./style.css";
import { withRouter } from "react-router";
import StorageService from "../../services/StorageService";
import Confetti from "react-confetti";

class DownloadPostSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: false,
    };

    // let selectedImages = StorageService.getData("selectedImages")
    //   ? JSON.parse(StorageService.getData("selectedImages"))
    //   : [];

    // if (selectedImages.length < 1) {
    //   this.props.history.push(
    //     "/" +
    //       this.props.match.params["event_name"] +
    //       "/" +
    //       this.props.match.params["campaign_name"] +
    //       "/find-your-photo"
    //   );
    // }
  }

  componentDidMount() {}

  goToDownload() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let app_type = params.get("app_type");
    let event = params.get("event");
    this.props.history.push(
      event != null && event != ""
        ? "/my-downloads?app_type=" + app_type + "&event=" + event
        : "/my-downloads?app_type=" + app_type
    );
  }

  render() {
    return (
      <div className="successOuter">
        <Confetti
          width={372}
          height={900}
          numberOfPieces={300}
          recycle={false}
          tweenDuration={20000}
        />
        <div id="cust_reg_container" className="successContentOuter">
          <div id="content-area">
            <h2 className="regSuccessTxt">Thank You</h2>
            {/* <p className="regSuccessTxtInfo">Welcome to Mahachef 2021</p> */}

            <p className="videoSubInfoMsg">
              Your high quality pictures are ready to be downloaded. Use the
              link below to go to the download page.
            </p>

            <div className="successImageOuter">
              <img
                src={require("../../assets/images/download-ready.jpg")}
                alt="success"
                className="reg-success-image"
              />
            </div>
          </div>

          <div id="footer">
            <div id="goToHomeLink">
              <a onClick={() => this.goToDownload()}>Go to my downloads</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(DownloadPostSuccess);
