import React, { useEffect, useState } from "react";

import Masonry from "react-responsive-masonry"
import SocialShare from '../SocialShare';
import { Button } from "@material-ui/core";
import {AiOutlineCheckCircle,AiOutlineCheck} from 'react-icons/ai'
import {BsCheckCircle} from 'react-icons/bs'
import './styles.css'

const Checkmark = ({ selected }) => (
   <div className="checkbox-container" style={{position:'absolute',top:5,right:5,background:selected ? 'rgb(34, 139, 34)' : 'rgba(0, 0, 0, 0.5)'}} >
    <AiOutlineCheck color="#fff"/>
   </div>
  );

  
const ImageBox =({image,selectedImage,item, onSelect,isForCertificate, onSelectCertificate,selectedCertificate})=>{
    const [isLoading, setIsLoading] = React.useState(true);
    const [selected, setSelected] = React.useState(selectedImage );

    // useEffect(()=>{
    //   onSelect(selected);
    // },[selected])
    return(
        <div className={selected ? 'img-box selected-image' : 'img-box'} onClick={()=>{
          if(!isForCertificate) {
           onSelect(!selected);setSelected(!selected);
          }else{
            onSelectCertificate();
          }

           }}>
          {(!isLoading && !isForCertificate) &&
            <Checkmark  selected={selected}/>
          }
            {isLoading &&  <img src={'https://via.placeholder.com/160x180.png?text=Loading...'} />   }
            <img src={image} data-item-id={item.id} onLoad={()=>{setIsLoading(false)}} className={(isForCertificate && selectedCertificate==item.id) ? "selected-image-certificate" : ""} style={{objectFit:'cover',display:isLoading ? 'none' : ''}}/>   
            {/* <Button style={{position:'absolute',bottom:10,right:0}} onClick={()=>{onClick(image)}}>
                <FiShare2 color={'#fff'} />    
            </Button>  */}
        </div>
    )
}

const ImageListing = ({onClick,images,onSelect,selectedImages, isForCertificate,onSelectCertificate})=>{
  const [listImages,setListImages] = useState([]);
  const [selectedCertificate,setSelectedCertificate] = useState(null);
  useEffect(()=>{

    const filteredArr = images.reduce((acc, current) => {
      const x = acc.find(item => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    setListImages(filteredArr);
  
  },[images])


  
    return(
        <div className="list-group list-group-vertical" style={{overflowY:'unset',paddingBottom:30}}>
            <Masonry columnsCount={2} gutter={'10px'} style={{paddingBottom:80}}>
                {
                    listImages.map(item=>
                        {
                         let selectedImage = selectedImages.filter(itemImage=>{
                            return itemImage.id == item.id;
                          })
                    return(
                    <ImageBox isForCertificate={isForCertificate} onSelectCertificate={()=>{onSelectCertificate(item);setSelectedCertificate(item.id)}} selectedCertificate={selectedCertificate} item={item} selectedImage={selectedImage.length} onSelect={(isSelected)=>{onSelect(item, isSelected)}} onClick={onClick} image={item.image}/>
                    )}
                    )
                }
                          
            </Masonry>
        </div>
    )
}


export default class ImageGallery extends React.Component {
    state ={
        socialShare:false,
        image:null,
    }

    render(){
        return(
        <>
           <ImageListing
           onSelectCertificate={this.props.onSelectCertificate}
           isForCertificate={this.props.isForCertificate}
              selectedImages={this.props.selectedImages}
              onSelect={this.props.onSelect}
              images={this.props.images}
              onClick={(item)=>{
                  this.setState({socialShare:true,image:item})}}
              />
              <SocialShare
              image={this.state.image}
              close={()=>{this.setState({socialShare:false})}}
              isOpen={this.state.socialShare}
              quote={''} hashtag={['']} url={this.state.image}/>
        </>)
    }
}