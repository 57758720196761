import React from "react";
import "./style.css";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withRouter } from "react-router";
import StorageService from "../../services/StorageService";
import { Constants } from "../../config";
import InputSpinner from "../../components/InputSpinner";


class CustomerRegistrationTicket extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ticket_count: StorageService.getData('ticket_count') ? StorageService.getData('ticket_count') : 0,
      previous_ticket_count: StorageService.getData('ticket_count') ? StorageService.getData('ticket_count') : 0,
      ticket_count_changed: false,
    };

    this.chooseTicket = this.chooseTicket.bind(this);
  }

  chooseTicket(value) {    
    if (this.state.previous_ticket_count != value) {
      this.setState({ ticket_count_changed: true });
    } else {
      this.setState({ ticket_count_changed: false });
    }

    this.setState({ ticket_count: value });
    StorageService.storeData('ticket_count', value);
  }

  viewEnrollmentForm() {
    if (this.state.ticket_count > 0) {

      if (this.state.ticket_count_changed) {
        let arr = [];
        StorageService.storeData('participant_arr', JSON.stringify(arr));
        this.setState({ ticket_count_changed: false });
      }

      this.props.history.push(
        "/" +
        this.props.match.params["event_name"] +
        "/" +
        this.props.match.params["campaign_name"] +
        "/registration"
      );
    } else {
      alert('Please choose the number of tickets.')
    }
  }

  goBack() {
    this.props.history.push(
      "/" +
      this.props.match.params["event_name"] +
      "/" +
      this.props.match.params["campaign_name"] +
      "/home"
    );
  }

  render() {
    return (
      <div id="cust_reg_container" className="main-container">
        <div id="header">
          <nav id="back" onClick={() => this.goBack()}></nav>
          <h1 id="page-title">Choose no of entries</h1>
        </div>

        <div id="content-area">

          <p className="ticketsInfo">
            Choose number of entries you would like to enroll, registration fee for each entry will be ₹ {Constants.TICKET_PRICE}
          </p>

          {/* <ul className="ticket-list">
            <li>
              <input type="radio" id="ticket1" name="ticket" value="1" checked={(this.state.ticket_count == 1) ? true : false} onChange={() => this.chooseTicket(1)} />
              <label for="ticket1">1</label>
            </li>
            <li>
              <input type="radio" id="ticket2" name="ticket" value="2" checked={(this.state.ticket_count == 2) ? true : false} onChange={() => this.chooseTicket(2)} />
              <label for="ticket2">2</label>
            </li>
            <li>
              <input type="radio" id="ticket3" name="ticket" value="3" checked={(this.state.ticket_count == 3) ? true : false} onChange={() => this.chooseTicket(3)} />
              <label for="ticket3">3</label>
            </li>
          </ul> */}

          <div className="inputSpinnerOuter">
            <InputSpinner max={3} min={0} default={this.state.ticket_count} step={1} onChangeSpinnerValue={this.chooseTicket}/>
          </div>

        </div>
        <div id="footer">
          <Button
            className="btn-done"
            size="large"
            onClick={() => this.viewEnrollmentForm()}
          >
            Next
          </Button>
        </div>
      </div>
    );
  }
}
export default withRouter(CustomerRegistrationTicket);
