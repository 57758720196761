import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TemplateImage from '../../../screens/Certificate';
import * as htmlToImage from 'html-to-image';
import S3 from "../../../services/aws/s3";
import ApiService from "../../../services/ApiService";

import {
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    WhatsappIcon,
    LinkedinIcon,
    PinterestIcon,
    TelegramIcon,
    TwitterIcon
  } from "react-share";


function calculatePixel(desiredPixel, pixelRatio) {
    pixelRatio = window.devicePixelRatio;
    // Check if pixel ratio is 1
    if (pixelRatio === 1) {
      return desiredPixel;
    } else {
      // Divide desired pixel by pixel ratio to get actual pixel
      return desiredPixel / pixelRatio;
    }
  }


  const SocialShare = ({url,quote,hashtag})=>{
    return (
        <div style={{display:'flex', justifyContent:'center'}}>
        <FacebookShareButton  openShareDialogOnClick={url !== "none"} url={url} quote={quote} hashtag={hashtag} ><FacebookIcon /></FacebookShareButton>
        <WhatsappShareButton url={url} title={quote}> <WhatsappIcon/> </WhatsappShareButton>
        <TwitterShareButton url={url} ><TwitterIcon/></TwitterShareButton>
        <LinkedinShareButton url={url} ><LinkedinIcon/> </LinkedinShareButton>      
        <TelegramShareButton url={url} ><TelegramIcon/></TelegramShareButton>
        </div>
    )
  }
export default function SelectImage({campaignData,title,isOpen, image,changeImage, templateData,handleClick,cancelFn,bidNumber}) {
    const [open, setOpen] = React.useState(isOpen);
    React.useEffect(()=>{
        setOpen(isOpen);
        // handleClick(isOpen);
    },[isOpen]);

   
    const [configData, setConfigData] = React.useState(templateData);
    const [certificate, setCertificate] = React.useState(null);
    const [imageWidth, setImageWidth] = React.useState(null);
    const [imageHeight, setImageHeight] = React.useState(null);
    const [isOpenShare, setIsOpenShare] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    React.useEffect(()=>{
      setConfigData(templateData);
    },[templateData]);


    React.useEffect(()=>{
        if(certificate){
            setIsOpenShare(true);
        }
    },[certificate])
  const createImage = () =>{
    setIsLoading(true);    
    setIsError(false); 
    const filter = (node) => {
      const exclusionClasses = ['Buttons','no-print'];
      return !exclusionClasses.some((classname) => node.classList?.contains(classname));
    }
    let options = {
      canvasHeight:calculatePixel(imageHeight),
      canvasWidth:calculatePixel(imageWidth),
      filter,
    };
    htmlToImage.toJpeg(document.getElementById('certificate'), options)
    .then(function (dataUrl) {
      let timestamp = + new Date();     
      S3.uploadCertificate(dataUrl,timestamp+'certificate.jpeg',(data)=>{
          setIsLoading(false);
            setCertificate(data.Location);
        })
    }).catch(err=>{
      setIsLoading(false);
      setIsError('Somethig went wrong. Please try again later');
    });
  }

  const Download = () =>{   
    // console.log(imageWidth, imageHeight)
    // return;
    const filter = (node) => {
      const exclusionClasses = ['Buttons','no-print'];
      return !exclusionClasses.some((classname) => node.classList?.contains(classname));
    }
    htmlToImage.toJpeg(document.getElementById('certificate'), {
        canvasHeight:calculatePixel(imageHeight),
        canvasWidth:calculatePixel(imageWidth),
        filter,        
      })
    .then(function (dataUrl) {
      var link = document.createElement('a');
      link.download = 'certificate.jpeg';
      link.href = dataUrl;
      link.click();
    });
  }

  return (
    <div>    
      <Dialog
        fullWidth
        open={open}
        disableEscapeKeyDown={false}
        disableBackdropClick={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >     
        <DialogTitle id="alert-dialog-title">
         <h6>{title}</h6>
        </DialogTitle>
        <DialogContent>
          {(configData) &&         
            <TemplateImage bidNumber={bidNumber} configData={configData} image={image} onChangeHeight={(height) => setImageHeight(height)} onChangeWidth={(width) => setImageWidth(width)}/> 
            || <p>Please wait. Loading Designer view...</p>
          }
        </DialogContent>
        
        {
            !certificate &&
            <DialogActions>
              {campaignData?.is_image_certificate &&
                        <Button onClick={changeImage}>Change Image</Button>
                || 
                <Button onClick={cancelFn}>Cancel</Button>
              }
        
        <Button onClick={createImage} autoFocus>
            {isLoading ? 'Generating...'  : 'Done'}
          </Button>                  
        </DialogActions>
        }
        
        {
            certificate &&
            <>
            <SocialShare 
            url={certificate} 
            quote={campaignData?.description}
            // hashtag={'#bmm #bangalore #marathon'}
            />
          <Button onClick={Download}>Download</Button>
          <Button onClick={()=>{setOpen(false);handleClick(false,true);setCertificate(null)}}>Close</Button>
            </>
        }
        {
          isError &&   <DialogActions><p > {isError}</p></DialogActions>
        }
        
      </Dialog>
    </div>
  );
}