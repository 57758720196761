import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import './styles.css'
import Select from 'react-select'
import ApiService from "../../../services/ApiService";
import StorageService from "../../../services/StorageService";
import S3 from "../../../services/aws/s3";

import { useFilePicker } from 'use-file-picker';

const ProfileImage = ({onSelect})=>{
    const [openFileSelector, { filesContent, loading }] = useFilePicker({
        accept: 'image/*',
        multiple:false,
        readAs:'DataURL',
      });

      const [imagePath,setImagePath] =  useState("/user-avatar.png");

      useEffect(()=>{
        if(filesContent.length > 0){
            onSelect(filesContent[0]);
            setImagePath(filesContent[0].content);
        }
      },[filesContent])
    return(
        <div className="wraper">
        <div className="profile-image">
            <img src={imagePath}/>          
        </div>
        <button  onClick={() => openFileSelector()}>Select Image</button>
    </div>
    )
}
class VerifyIdentity extends React.Component {
    
    state ={
      image:null,
      event_name:this.props.match.params["event_name"],
      campaign_name:this.props.match.params["campaign_name"]
    };

    componentDidMount(){
        
    }

    render(){
        return(
            <div className="image-upload register-form">
                <div className="header">
                    <div onClick={()=>this.props.history.push("/" + this.state.event_name + "/" + this.state.campaign_name + "/complete-profile")}>
                    <img src="/left-arrow.svg"/>
                    </div>
                    <h4>Upload your image</h4>
                </div>

               <ProfileImage onSelect={(image)=>this.setState({image:image})}/>
                <div>
                    <p>* Please upload your own image as profile picture. By uploading your image you'll get all your images in your image tab. Our advanced face recognition algorithm will match the images </p>
                    <h5>Note :</h5>
                    <ul>
                        <li>Upload your own image. </li>
                        <li>Do not upload group image. </li>
                        <li>Make sure the image has good quality and your face.</li>
                    </ul>                  
                </div>

                <button disabled={!this.state.image ? 'true' : ''} onClick={()=>this.uploadImage()}>Next</button>
                
            </div>
        )
    }
    uploadImage(){
        let image = this.state.image;      
        let timestamp = + new Date();     
        S3.upload(image.content,timestamp+image.name,(data)=>{
            this.saveProfileLink(data.Location);
        });
    }

    saveProfileLink(link){
        let user =  JSON.parse(StorageService.getData("user_details"));
        let postParams = {
            profile_image:link,
        };
        ApiService.postData("updateProfileImage", postParams, true).then(
            (response) => {
                if (response.status == "success") {
                    let newData = {
                        ...user,
                        profile_image:link,
                    };
                    StorageService.storeData("user_details", JSON.stringify(newData));
                    this.findImageUsingFaceRecognition();
                    this.props.history.push("/" + this.state.event_name + "/" + this.state.campaign_name + "/speakers");
                }
            }).catch(e=>{

            })
    }
    
}

export default withRouter(VerifyIdentity);
