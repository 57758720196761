import StorageService from '../StorageService';
const axios = require('axios');

export default {
   generateRandomString(length){
          let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
   },
   calculatePrice(imageCount,downloaded){
    let eventPricing = StorageService.getData("paymentSlab")  ? JSON.parse(StorageService.getData("paymentSlab")) : [];
    if(eventPricing?.name == undefined) return null;
    let flatRate  = eventPricing?.flat[0];
    let stepsPrice = eventPricing?.step;
    let totalPrice = 0;  
    if(downloaded >=  flatRate.count) return 0;
      //If the imageCount is greater than or equal to flat rate;
      if(imageCount >= flatRate.count){
        totalPrice = flatRate.price;
      }else{
        
            for (let i = 0; i < stepsPrice.length; i++) {
              const { price, count } = stepsPrice[i];

              if (imageCount >= count) {
                totalPrice += count * price;
                imageCount -= count;
              } else {
                totalPrice += imageCount * price;
                imageCount = 0;
                break;
              }
            }

            if (imageCount > 0) {
              totalPrice += imageCount * stepsPrice[stepsPrice.length - 1].price;
            }       
      }
      return totalPrice;
    },
    // calculatePrice(imageCount,downloaded){
    //     let eventPricing = StorageService.getData("paymentSlab")  ? JSON.parse(StorageService.getData("paymentSlab")) : [];
    //     if(eventPricing?.name == undefined) return null;
    //     let flatRate  = eventPricing?.flat[0];
    //     let stepsPrice = eventPricing?.step;
    //     let totalPrice = 0;  
    //     if(downloaded >=  flatRate.count) return 0;
    //       //If the imageCount is greater than or equal to flat rate;
    //       if(imageCount >= flatRate.count){
    //         totalPrice = flatRate.price;
    //       }else{
    //         let checkPrice = stepsPrice.filter(item=>item.count==imageCount);
    //           if(checkPrice.length > 0){ //If the price range exist in array
    //             totalPrice = checkPrice[0].price;
    //           }else {
    //             totalPrice = this.calculateCustomCountPrice(stepsPrice,imageCount)
    //           }
    //       }
    //       return totalPrice;
    //     },
      calculateCustomCountPrice(stepsPrice,count){
          let data = stepsPrice.filter(item=>item.count < count);
          let price = 0;
          let remainingCount = count;
        
          for (let i = 0; i < data.length; i++) {
            const obj = data[i];
            const itemsCount = Math.min(remainingCount, obj.count);
            price += obj.price;
            remainingCount -= itemsCount;
        
            if (remainingCount === 0) {
              break;
            }
          }
        
          return price;
      },
      async getS3ImageAsBinary(s3ImageUrl) {
        try {
          const response = await axios.get(s3ImageUrl, { responseType: 'arraybuffer' });
      
          // The response data is in binary format
          const binaryData = response.data;
      
          // If you want to save the binary data to a file (optional)
          // fs.writeFileSync('image.jpg', Buffer.from(binaryData, 'binary'));
      
          return binaryData;
        } catch (error) {
          console.error('Error fetching or converting image:', error);
          throw error;
        }
      }
}