import React from "react";
import './style.css'
import Header from '../../../components/Conference/Header'
import { withRouter } from "react-router";
import Masonry from "react-responsive-masonry"
import {FiShare2} from 'react-icons/fi'
import { Button } from "@material-ui/core";
import SocialShare from '../../../components/SocialShare';
  
const ImageBox =({image,onClick})=>{
    return(
        <div className="img-box">
            <img src={image}/>   
            <Button style={{position:'absolute',bottom:10,right:0}} onClick={()=>{onClick(image)}}>
                <FiShare2 color={'#fff'} />    
            </Button> 
        </div>
    )
}

const images = [
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnZs3sksAyTeVj_U3wKtTC1rhyciLyMTQUBw_a5N4OMEB7g_dwbDLGVvXm1ZSmUIy3za0&usqp=CAU',
    'https://www.instantstories.com/uploads/stories/pooja-hegde/pooja-hegde-latest-hot-hd-photoswallpapers-1080p4k-zm88-lg.jpg?v=1569656046',
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEEUBRgqOSf4wOdT_G_dH9JEGKaXYsv34B2Q&usqp=CAU',
    'https://www.thenewsminute.com/sites/default/files/styles/news_detail/public/Tovino_Thomas_23012021_1200-compressed.jpg?itok=b8nVDYxD',
    'https://timesofindia.indiatimes.com/photo/87887382.cms',
    'https://staticimg.spicyonion.com/images/profile/artist/nazriya-nazim.jpg'
];

const ImageListing = ({onClick})=>{
    return(
        <div className="list-group list-group-vertical">
            <Masonry columnsCount={2} gutter={'10px'}>
                {
                    images.map(image=>(
                    <ImageBox onClick={onClick} image={image}/>
                    ))
                }
                          
            </Masonry>
        </div>
    )
}
class ImageGallery extends React.Component {
state ={
    socialShare:false,
    image:null,
}

componentDidMount() {

}

render(){
    return(
        <div>
            
            <Header />
                <div className="tab-bar">
                    <div className="active">
                        <p >Image Gallery</p>
                    </div>
                    
                </div>

                <ImageListing
                onClick={(item)=>{
                    this.setState({socialShare:true,image:item})}}
                />
               <SocialShare
               image={this.state.image}
               close={()=>{this.setState({socialShare:false})}}
               isOpen={this.state.socialShare}
               quote={'Campaign Conference'} hashtag={['#conference',"#photo-contest"]} url={this.state.image}/>
        </div>
    )
}
}


export default withRouter(ImageGallery);
