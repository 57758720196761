import React from "react";
import "./App.css";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Landing from "./screens/Landing";
import Login from "./screens/Login";
import CustomerRegistration from "./screens/CustomerRegistration";
import CustomerRegistrationPayment from "./screens/CustomerRegistrationPayment";
import CustomerRegistrationTicket from "./screens/CustomerRegistrationTicket";
import EventDetail from "./screens/EventDetail";
import CreatePost from "./screens/CreatePost";
import CreateVideoPost from "./screens/CreateVideoPost";
import AuthenticationService from "./services/AuthenticationService";
import Profile from "./screens/Profile";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import ErrorPage from "./screens/ErrorPage";
import AllPosts from "./screens/AllPosts";
import AllPostsGallery from "./screens/AllPostsGallery";
import ExpiredPage from "./screens/ExpiredPage";
import InvalidPage from "./screens/InvalidPage";
import CustomerRegistrationSuccess from "./screens/CustomerRegistrationSuccess";
import CampaignParticipants from "./screens/CampaignParticipants";
import CreateVideoPostSuccess from "./screens/CreateVideoPostSuccess";
import AllParticipants from "./screens/AllParticipants";
import DownloadPosts from "./screens/DownloadPosts";
import FaceRecognitionSearch from "./screens/FaceRecognitionSearch";

import DownloadPostSummary from "./screens/DownloadPostSummary";
import DownloadPostSuccess from "./screens/DownloadPostSuccess";
import MyDownloads from "./screens/MyDownloads";


import Registration from "./screens/Conference/Registration";
import UploadProfile from "./screens/Conference/Registration/verify-identity";
import ParticipantWall from "./screens/Conference/ParticipantWall";
import Attendee from "./screens/Conference/ParticipantWall/attendee";
import VideoGallery from "./screens/Conference/VideoGallery";
import ImageGallery from "./screens/Conference/ImageGallery";
import ConferenceProfile from "./screens/Conference/Profile";
import ApiService from "./services/ApiService";

import StorageService from "./services/StorageService";
import BidSearch from './screens/Guest/BidSearch'
import toast, { Toaster } from 'react-hot-toast';
import { Constants } from "./config";

//Certificate
import CerificateScreen from "./screens/Certificate";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      capaignDetails:null
    }

    let search = window.location.search;
    let params = new URLSearchParams(search);
    //Commented For Sales Event

    if(params.get("fr_event_id")) {
      StorageService.storeData('fr_event_id', params.get("fr_event_id"));   
      StorageService.storeData('campaign_name', "");    
    }

    if(params.get("campaign_name")) {
      StorageService.storeData('campaign_name', params.get("campaign_name"));      
    }

    if(params.get("app_type")) {
      StorageService.storeData('app_type', params.get("app_type"));      
    }

    this.getCampaignDetails();
    
  }

  getCampaignDetails(){
    let params = new URLSearchParams(window.location.search);
    let campaign_name = StorageService.getData("campaign_name") ? StorageService.getData("campaign_name") : params.get("campaign_name");
     ApiService.getData('getCapaign', {slug:campaign_name}, false).then((response) => {     
      if(!!response.data){
        if(response.data[0]?.image_console_id !=undefined) {
          // console.log('imageconsole',response.data[0]?.image_console_id)
          this.getPaymentSlab(response.data[0]?.image_console_id);
          StorageService.storeData('campaign_data',JSON.stringify(response.data[0]));
          StorageService.storeData('fr_event_id',response.data[0]?.image_console_id);
        }else{
          StorageService.storeData('campaign_data','{}');
          StorageService.storeData('paymentSlab','{}');

        }
          this.setState({capaignDetails:response.data[0]})
      }
    })
  }

  /**
   * Get Payment Slabs
   */
  getPaymentSlab(event_id){
    ApiService.imgConsoleGetData(`events/payment/participant/detailed-options?event_id=${event_id}`, {}, true).then(
      (response) => {
        let priceDetails = response[0];
        let priceSlab = Object.values(priceDetails)[0];
        priceSlab.id = Object.keys(priceDetails)[0];

        StorageService.storeData('paymentSlab',JSON.stringify(priceSlab));
      })

  }

  
  render() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let event_name = params.get("event_name");
    let campaign_name = params.get("campaign_name");
    let app_type = params.get("app_type");
    let event = params.get("event");


    let url_addon = "";

  
    if (event && event != "" ) url_addon = "&event=" + event;
    if(!this.state.capaignDetails && params.get("campaign_name")) return null;

    return (
      <Router>
        <div>
          <Switch>
        {/* Campaign Conference Routes */}
          <Route exact 
              path="/:event_name/:campaign_name/upload-image"
          component={UploadProfile} />

          <Route exact 
              path="/:event_name/:campaign_name/speakers"
          component={ParticipantWall} />

          <Route exact 
              path="/:event_name/:campaign_name/attendee"
          component={Attendee} />

          <Route exact 
             path="/:event_name/:campaign_name/complete-profile"
          component={Registration} />
           
             <Route exact 
             path="/:event_name/:campaign_name/videos"
          component={VideoGallery} />

          <Route exact 
              path="/:event_name/:campaign_name/images"
          component={ImageGallery} />

<Route exact 
              path="/:event_name/:campaign_name/profile"
          component={ConferenceProfile} />


      {/* Campaign Conference Routes */}

            <Route exact path="/landing" component={Landing} />
            <Route exact path={'/guest/bib-search'} component={BidSearch} />
            <Route
              exact
              path="/login"
              render={(props) => {
                return AuthenticationService.isUserLoggedIn() !== true ? (
                  <Login />
                ) : (
                  // <Redirect
                  //   to={
                  //     "/" + event_name + "/" + campaign_name + "/speakers"
                  //   }
                  // />
                  <Redirect
                    to={                      
                      this.state.capaignDetails?.type==3 && 
                      "/" + event_name + "/" + campaign_name + "/speakers" || (
                        this.state.capaignDetails?.type==  4 || app_type=='photo-app'
                        ? "/find-your-photo?app_type=photo-app"
                        : "/" + event_name + "/" + campaign_name + "/home")
                    }
                  />
                );
              }}
            />

            <Route
              exact
              path="/:event_name/:campaign_name/registration"
              render={(props) => {
                return AuthenticationService.isUserLoggedIn() === true ? (
                  <CustomerRegistration />
                ) : (
                  <Redirect
                    to={
                      "/" +
                      props.match.params["event_name"] +
                      "/" +
                      props.match.params["campaign_name"] +
                      "/login"
                    }
                  />
                );
              }}
            />

            <Route
              exact
              path="/:event_name/:campaign_name/registration-payment"
              render={(props) => {
                return AuthenticationService.isUserLoggedIn() === true ? (
                  <CustomerRegistrationPayment />
                ) : (
                  <Redirect
                    to={
                      "/" +
                      props.match.params["event_name"] +
                      "/" +
                      props.match.params["campaign_name"] +
                      "/login"
                    }
                  />
                );
              }}
            />

            <Route
              exact
              path="/:event_name/:campaign_name/registration-ticket"
              render={(props) => {
                return AuthenticationService.isUserLoggedIn() === true ? (
                  <CustomerRegistrationTicket />
                ) : (
                  <Redirect
                    to={
                      "/" +
                      props.match.params["event_name"] +
                      "/" +
                      props.match.params["campaign_name"] +
                      "/login"
                    }
                  />
                );
              }}
            />

            <Route
              exact
              path="/:event_name/:campaign_name/registration-success"
              render={(props) => {
                return AuthenticationService.isUserLoggedIn() === true ? (
                  <CustomerRegistrationSuccess />
                ) : (
                  <Redirect
                    to={
                      "/" +
                      props.match.params["event_name"] +
                      "/" +
                      props.match.params["campaign_name"] +
                      "/login"
                    }
                  />
                );
              }}
            />

            <Route
              exact
              path="/:event_name/:campaign_name/video-post-success"
              render={(props) => {
                return AuthenticationService.isUserLoggedIn() === true ? (
                  <CreateVideoPostSuccess />
                ) : (
                  <Redirect
                    to={
                      "/" +
                      props.match.params["event_name"] +
                      "/" +
                      props.match.params["campaign_name"] +
                      "/login"
                    }
                  />
                );
              }}
            />

            <Route
              exact
              path="/:event_name/:campaign_name/participants"
              render={(props) => {
                return AuthenticationService.isUserLoggedIn() === true ? (
                  <CampaignParticipants />
                ) : (
                  <Redirect
                    to={
                      "/" +
                      props.match.params["event_name"] +
                      "/" +
                      props.match.params["campaign_name"] +
                      "/login"
                    }
                  />
                );
              }}
            />

            <Route
              exact
              path="/:event_name/:campaign_name/home"
              render={(props) => {
                return AuthenticationService.isUserLoggedIn() === true ? (
                  <EventDetail />
                ) : (
                  <Redirect
                    to={
                      "/" +
                      props.match.params["event_name"] +
                      "/" +
                      props.match.params["campaign_name"] +
                      "/login"
                    }
                  />
                );
              }}
            />

            <Route
              exact
              path="/:event_name/:campaign_name/create-post"
              render={(props) => {
                return AuthenticationService.isUserLoggedIn() === true ? (
                  <CreatePost />
                ) : (
                  <Redirect
                    to={
                      "/" +
                      props.match.params["event_name"] +
                      "/" +
                      props.match.params["campaign_name"] +
                      "/login"
                    }
                  />
                );
              }}
            />
            <Route
              exact
              path="/:event_name/:campaign_name/create-video-post"
              render={(props) => {
                return AuthenticationService.isUserLoggedIn() === true ? (
                  <CreateVideoPost />
                ) : (
                  <Redirect
                    to={
                      "/" +
                      props.match.params["event_name"] +
                      "/" +
                      props.match.params["campaign_name"] +
                      "/login"
                    }
                  />
                );
              }}
            />

            <Route
              exact
              path="/:event_name/:campaign_name/my-profile"
              render={(props) => {
                return AuthenticationService.isUserLoggedIn() === true ? (
                  <Profile />
                ) : (
                  <Redirect
                    to={
                      "/" +
                      props.match.params["event_name"] +
                      "/" +
                      props.match.params["campaign_name"] +
                      "/login"
                    }
                  />
                );
              }}
            />

            <Route
              exact
              path="/my-profile"
              render={(props) => {
                return AuthenticationService.isUserLoggedIn() === true ? (
                  <Profile />
                ) : (
                  <Redirect to={"/login?app_type=" + app_type + url_addon} />
                );
              }}
            />

            <Route
              exact
              path="/find-your-photo"
              render={(props) => {
                return AuthenticationService.isUserLoggedIn() === true ? (
                  <FaceRecognitionSearch />
                ) : (
                  <Redirect
                    to={
                      app_type == "photo-app"
                        ? "/login?app_type=" + app_type + url_addon
                        : "/" + event_name + "/" + campaign_name + "/login"
                    }
                  />
                );
              }}
            />

            <Route
              exact
              path="/download-posts-summary"
              render={(props) => {
                return AuthenticationService.isUserLoggedIn() === true ? (
                  <DownloadPostSummary />
                ) : (
                  <Redirect
                    to={
                      app_type == "photo-app"
                        ? "/login?app_type=" + app_type + url_addon
                        : "/" + event_name + "/" + campaign_name + "/login"
                    }
                  />
                );
              }}
            />

            <Route
              exact
              path="/download-posts-success"
              render={(props) => {
                return AuthenticationService.isUserLoggedIn() === true ? (
                  <DownloadPostSuccess />
                ) : (
                  <Redirect
                    to={
                      app_type == "photo-app"
                        ? "/login?app_type=" + app_type + url_addon
                        : "/" + event_name + "/" + campaign_name + "/login"
                    }
                  />
                );
              }}
            />

            <Route
              exact
              path="/my-downloads"
              render={(props) => {
                return AuthenticationService.isUserLoggedIn() === true ? (
                  <MyDownloads />
                ) : (
                  <Redirect
                    to={
                      app_type == "photo-app"
                        ? "/login?app_type=" + app_type + url_addon
                        : "/" + event_name + "/" + campaign_name + "/login"
                    }
                  />
                );
              }}
            />

            <Route
              exact
              path="/:event_name/:campaign_name/campaign-wall"
              component={AllPosts}
            />

            <Route
              exact
              path="/:event_name/:campaign_name/all-participants"
              component={AllParticipants}
            />

            <Route
              exact
              path="/:event_name/:campaign_name/gallery"
              component={AllPostsGallery}
            />

            <Route
              exact
              path="/:event_name/:campaign_name/privacy-policy"
              component={PrivacyPolicy}
            />

            <Route
              exact
              path="/:event_name/:campaign_name/error"
              component={ErrorPage}
            />

            <Route
              exact
              path="/:event_name/:campaign_name/expired"
              component={ExpiredPage}
            />
            <Route
              exact
              path="/:event_name/:campaign_name/invalid"
              component={InvalidPage}
            />

            {/* Certificate Pages */}
            <Route
              exact
              path="/geterate-certificate"
              component={CerificateScreen}
            />
            
          </Switch>
        </div>
         <Toaster />
         <div className="footer">
          <p>Elevate your event experience with <a href="https://home.eventforce.ai/" target="__blank">Eventforce</a>.</p>
         </div>
      </Router>
    );
  }
}

export default App;
