import React, { useState } from "react";
import "./style.css";
import Button from "@material-ui/core/Button";
import { AiOutlineCaretRight } from "react-icons/ai";
import AnimateHeight from "react-animate-height";
import { useSwipeable } from "react-swipeable";
import SwipeUp from "../../assets/images/swipe-up.gif";
import { AiOutlineClose } from "react-icons/ai";
import StorageService from "../../services/StorageService";

const DownloadPostButton = ({
  onClickDownloadButton,
  count,
  totalPrice,
  endText,
  type,
  paymentSlab,
  purchasedImages,
  is_this_sales_event
}) => {
  const eventDetail = JSON.parse(StorageService.getData("selected_event"));
  let flatRate  = (is_this_sales_event && paymentSlab?.flat!=undefined) ? paymentSlab?.flat[0] : {};
  let stepsPrice = (is_this_sales_event && paymentSlab?.step!=undefined) ? paymentSlab?.step : [];

  const [height, setHeight] = useState(0);
  const [duration, setDuration] = useState(500);

  const toggleInfoSection = (eventData, type) => {
    if (type == "up") {
      document.body.style.overflow = "hidden";
      setTimeout(() => {
        setHeight(300);
      }, 100);
    }

    if (type == "down") {
      setHeight(0);
      setTimeout(() => {
        document.body.style.overflow = "inherit";
      }, 100);
    }
  };

  let config = {
    delta: 10, // min distance(px) before a swipe starts. *See Notes*
    preventDefaultTouchmoveEvent: false, // call e.preventDefault *See Details*
    trackTouch: true, // track touch input
    trackMouse: false, // track mouse input
    rotationAngle: 0, // set a rotation angle
  };

  const handlers = useSwipeable({
    onSwipedUp: (eventData) => toggleInfoSection(eventData, "up"),
    onSwipedDown: (eventData) => toggleInfoSection(eventData, "down"),
    ...config,
  });

  return (
    <div className="buyPhotoOuter">
      <div className="buttonWrapper width100">
        <div className="width40 floatLeft textLeft" {...handlers}>
          <p className="swipeTxt">
          {type !='btn_save' &&
            <a
              onClick={() => toggleInfoSection({}, height == 0 ? "up" : "down")}
            >
              {height == 0
                ? "Swipe up or click here to know pricing structure"
                : "Swipe down or click here to close"}
            </a>
      }
          </p>
        </div>
        <div className="width55 floatLeft textRight clickableArea">
          <Button
            className="buyPhotoBtn"
            onClick={() => onClickDownloadButton()}
          >
            <div className="width50 floatLeft textLeft">
              <p className="itemCount">{count} photos</p>
              {type !='btn_save' && <p className="totalPrice">₹ {totalPrice} <span style={{fontSize:12}}>{totalPrice > 0 ? '+GST' :''}</span></p>}
            </div>
            <div className="width50 floatLeft">
              <p className="buttonEndText">
                {endText}
                <AiOutlineCaretRight className="nextIcon" />
              </p>
            </div>
          </Button>
        </div>
      </div>
      {type !='btn_save' &&
      <AnimateHeight className="buyInfo" duration={duration} height={height}>
        <div className="buyInfoContent">
          <h3 className="conditionTitle">Pricing structure</h3>
          
            {
              
              stepsPrice.map((item,index)=>(<p className="conditionTxt">{index==0 ? 'First' : 'Next'} {item.count} Images <b>₹{item.price}</b> /image</p>))
            }
            
            {/* {eventDetail?.photo_price_type_id == 1
              ? "All photos are for FREE"
              : "Get any number of photos for ₹" +
                eventDetail?.photo_price +
                " flat. "} */}
          
          <p className="conditionTxt">
            Select more than {flatRate.count} Images  and pay <b>₹{flatRate.price}</b> +GST only
          </p>
          {
            purchasedImages > 0 &&
            <h3 className="conditionTitle" style={{marginTop:20}}>You've purchased ({purchasedImages}) image</h3>
          }
          <p className="text-center closeOuter">
            <a
              onClick={() => toggleInfoSection({}, "down")}
              className="closeLink"
            >
              <AiOutlineClose className="closeIcon" /> Close
            </a>
          </p>
        </div>
      </AnimateHeight>
  }
    </div>
  );
};
export default DownloadPostButton;
