import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import "./style.css";
import StorageService from "../../../services/StorageService";
import { FiAlignJustify } from "react-icons/fi";

import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function LeftDrawer(props) {
  const { event_name, campaign_name } = useParams();

  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const [campaign_details, setCampainDetails] = React.useState(
    JSON.parse(StorageService.getData("campaign_details"))
  );

  const [user_details, setUserDetails] = React.useState(
    JSON.parse(StorageService.getData("user_details"))
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const onCLickLogout = () => (event) => {
    console.log('User logout');
    //console.log("onCLickLogout");
    StorageService.removeData("access_token");
    StorageService.removeData("campaign_details");
    StorageService.removeData("user_details");
    StorageService.removeData("event_id");
    StorageService.removeData("selected_event");
    StorageService.removeData("selectedImages");
    StorageService.removeData("lowResImages");
    StorageService.removeData("bidNo");
  };

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const app_type = params.get("app_type");
  const event = params.get("event");

  // const event_name = params.get("event_name");
  // const campaign_name = params.get("campaign_name");

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
      onClick={toggleDrawer(anchor, false)}
    >
      <div className="profile-section">
      <div className="menu-user-img">
        <img src={user_details.profile_image}/>
        </div>
        <div className="user-text-container">
          <h6>{user_details.name}</h6>
          <span>{user_details.email}</span>
        </div>
        </div>
      <List>
      
      <ListItem button key="participant-wall">
        
        <Link
          className="meni-item-link"
          to={'/'+event_name+'/'+campaign_name+'/profile'}
        >
          <ListItemText primary="profile" />
        </Link>
      </ListItem>
     

      <ListItem button key="participant-wall">
        
          <Link
            className="meni-item-link"
            to={'/'+event_name+'/'+campaign_name+'/speakers'}
          >
            <ListItemText primary="participant wall" />
          </Link>
        </ListItem>
       

        <ListItem button key="videos">
          <Link
            className="meni-item-link"
            to={'/'+event_name+'/'+campaign_name+'/videos'}
          >
            <ListItemText primary="videos" />
          </Link>
        </ListItem>

        <ListItem button key="images">
          <Link
            className="meni-item-link"
            to={'/'+event_name+'/'+campaign_name+'/images'}
          >
            <ListItemText primary="photos" />
          </Link>
        </ListItem>

        <ListItem button key="logout">
          <Link
            className="meni-item-link"
            to={`/login?event_name=${event_name}&campaign_name=${campaign_name}`}
          >
            <ListItemText onClick={onCLickLogout()}  primary="logout" />
          </Link>
        </ListItem>
       
      </List>
    </div>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
         
            <FiAlignJustify onClick={toggleDrawer(anchor, true)} size="20"/>
         
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
