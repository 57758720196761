import React from "react";
import './style.css'
import Header from '../../../components/Conference/Header'
import { withRouter } from "react-router";
import ApiService from "../../../services/ApiService";

const UserBox =({user,item})=>{
    return(
        <div className="user-box" style={{marginBottom:20}}>         
        <img src={user.profile_image}/>
            <div className="user-text-wraper">
            <span>{user.name}</span>
            <span>{item.company_name}</span>
        </div>
    </div>
    )
}


const ParticipantListing = ({item})=>{

   return item.users.map(user=>(
                <UserBox user={user} item={item}/>                                                     
            ))
                        
}
class ParticipantWall extends React.Component {

        state ={
            users:[],
        }
    componentDidMount(){
        this.getData();
    }

    getData(){
        let getParams ={
            campaign_slug:this.props.match.params["campaign_name"],
            type:'speaker',
        };
        // speaker
        ApiService.getData("getParticipant", getParams, true).then((response) => {  
            if(response.status =='success'){
                let {data} = response;
                this.setState({users:data});
                
            }  
        })
    }
render(){
    let event_name  = this.props.match.params["event_name"];
    let campaign_name  =   this.props.match.params["campaign_name"];
    return(
        <div>
            <Header />
                <div className="tab-bar">
                    <div className="active" onClick={()=>{ this.props.history.push("/" + event_name + "/" + campaign_name + "/speakers");}}>
                        <p >Speaker</p>
                    </div>
                    <div onClick={()=>{ this.props.history.push("/" + event_name + "/" + campaign_name + "/attendee");}}>
                        <p>Attendee</p>
                    </div>
                </div>
                <div className="speaker-listing">
                {
                this.state.users.length > 0 && 
                this.state.users.map(item=>(<ParticipantListing item={item}/>)) 
||
                <div className="empty-state"><p>Oh no we think the speaker's are not yet confirmed. All the speakers will list here</p></div>
                }     
                </div>              
        </div>
    )
}
}


export default withRouter(ParticipantWall);
