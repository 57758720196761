import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client'
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./assets/fonts/baloo/BalooDa2-Bold.ttf";
import "./assets/fonts/baloo/BalooDa2-Medium.ttf";
import "./assets/fonts/baloo/BalooDa2-Regular.ttf";
import "./assets/fonts/baloo/BalooDa2-SemiBold.ttf";

import "./assets/fonts/montserrat/Montserrat-Bold.ttf";
import "./assets/fonts/montserrat/Montserrat-Light.ttf";
import "./assets/fonts/montserrat/Montserrat-Medium.ttf";
import "./assets/fonts/montserrat/Montserrat-Regular.ttf";
import "./assets/fonts/montserrat/Montserrat-SemiBold.ttf";

// import "./assets/js/jquery-3.5.1.min.js";
// import "./assets/js/konva.min.js";
// import "./assets/js/script.js";


createRoot(document.getElementById('root')).render( <React.StrictMode>
  <App />
</React.StrictMode>)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
