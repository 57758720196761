import React from "react";
import "./style.css";
import { withRouter } from "react-router";
import StorageService from "../../services/StorageService";
import Confetti from 'react-confetti'
import ApiService from "../../services/ApiService";
import ParticipantList2 from "../../components/ParticipantList2";
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';

// const { width, height } = useWindowSize()

class CustomerRegistrationSuccess extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            participantArr: [],
            campaign_details: JSON.parse(StorageService.getData('campaign_details')),
            is_loading: false
        };
    }

    async componentDidMount() {
        await this.getAllParticipants();
    }
    

    goToHome() {
        this.props.history.push(
            "/" +
            this.props.match.params["event_name"] +
            "/" +
            this.props.match.params["campaign_name"] +
            "/home"
        );
    }

    async getAllParticipants(){
        let getParams = {
            campaign_id: this.state.campaign_details[0].id,
            status:1,
            pageCount:4,
            page:1,
        };

        ApiService.getData("getAllParticipants", getParams, true).then((response) => {    
            if (response.status == "success") {  
                this.setState({is_loading : false});
                this.setState({participantArr : response.data.data});     
            } else {      
                if (response.message == "Token has expired") { 
                    StorageService.removeData("access_token");
                    StorageService.removeData("campaign_details");
                    StorageService.removeData("user_details");
                    this.props.history.push(
                        "/" +
                        this.props.match.params["event_name"] +
                        "/" +
                        this.props.match.params["campaign_name"] +
                        "/login"
                    );
                } else {
                this.props.history.push(
                    "/" +
                    this.props.match.params["event_name"] +
                    "/" +
                    this.props.match.params["campaign_name"] +
                    "/error"
                );
                }
            }
        });
    }

    redirectToAllParticipantsList(){
        this.props.history.push(
            "/" +
            this.props.match.params["event_name"] +
            "/" +
            this.props.match.params["campaign_name"] +
            "/all-participants"
        );
    }

    render() {
        return (
            <div className="successOuter">
                <Confetti
                    width={372}
                    height={900}
                    numberOfPieces={300}
                    recycle={false}
                    tweenDuration={20000}
                />
                <div id="cust_reg_container" className="successContentOuter">
                    <div id="content-area">
                        <h2 className="regSuccessTxt">
                            Congrats
                        </h2>  
                        <p className="regSuccessTxtInfo">
                            Welcome to Mahachef 2021
                        </p>   

                        <p className="videoSubInfoMsg">
                            Thank you for registering to Mahachef 2021. 
                            Video subimissions will be enabled from Novmber 1, 2021
                        </p>   

                        <div className="successImageOuter">
                            <img
                                src={require("../../assets/images/reg-success.jpg")}
                                alt="success"
                                className="reg-success-image"
                            />
                        </div>

                    </div>

                    <div className="recentParticipantsList">
                        <div className="recentPartHeadOuter">
                            <p className="recentPartHead">
                                Recent Paticipants                            
                            </p> 
                           <a className="viewAllParticipantPageLink" onClick={() => this.redirectToAllParticipantsList()}>View more </a>
                        </div>

                        <Divider />
                        {(this.state.participantArr.length > 0) && this.state.participantArr.map((item, key) => (
                            <ParticipantList2
                                data={item}
                                key={key}
                                hide_button = {true}
                            />
                        ))}                       
                       
                    </div>

                    <div id="footer">
                        <div id="goToHomeLink">
                            <a onClick={() => this.goToHome()}>Go back to home</a>
                        </div>
                    </div>

                </div >
            </div>
        );
    }
}
export default withRouter(CustomerRegistrationSuccess);
