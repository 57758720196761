import * as React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { withRouter } from "react-router";
import "./style.css";

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

    
class ParticipantCard extends React.Component {

    constructor(props) {
        super(props);       
    }

    showVideoUploadPage(participant){       
        this.props.history.push({
            pathname :  '/' + this.props.match.params["event_name"] + "/" + this.props.match.params["campaign_name"] + "/create-video-post",
            data : participant
        });
    }

    render() {
        return (
            <Card sx={{ minWidth: 275 }} onClick={() => this.showVideoUploadPage(this.props.data)}>
                <CardContent>
                    <div className="card-content-outter">
                        <div className="text-center">
                            <label className="name">{this.props.data.name ? this.props.data.name : 'unknown'}</label>
                            {/* <label className="age">25 Years</label> */}
                        </div>
                        <div className="text-center district">
                            <label className="">{this.props.data.district ? this.props.data.district : 'unknown'}</label>
                        </div>
                        <Divider />

                        <div className="age section">
                            {/* <Chip variant="outlined" icon={<PhoneIphoneIcon />} label="+91 9388123 787" /> */}
                            <label className="label">Age: </label>
                            <label className="value">{this.props.data.age ? this.props.data.age : 'unknown'}</label>
                        </div>

                        <div className="phone section">
                            {/* <Chip variant="outlined" icon={<PhoneIphoneIcon />} label="+91 9388123 787" /> */}
                            <label className="label">Phone: </label>
                            <label className="value"> {this.props.data.phone ? '+91 ' + this.props.data.phone : 'unknown'}</label>
                        </div>

                        <div className="parent_name section">
                            <label className="label">Parent: </label>
                            <label className="value">{this.props.data.parent_name ? this.props.data.parent_name : 'unknown'}</label>
                        </div>

                        <div className="schl_name section">
                            <label className="label">School: </label>
                            <label className="value">{this.props.data.school_name ? this.props.data.school_name : 'unknown'}</label>
                        </div>
                    </div>
                </CardContent>
            </Card>
        );
    }
}

export default withRouter(ParticipantCard);